import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './RouteHandler/PrivateRoute'; 
import { AllUsers } from './pages/AllUsers';
import { DashboardV2 } from './pages/DashboardV2';
import { Logout } from './pages/Logout';
import { Registration } from './pages/Registration';
import { MainRegister } from './pages/MainRegister';
import { Reports } from './pages/Reports';
import JuiceScanner from './pages/JuiceScanner';
import { Login } from './pages/Login';
import { LoginAdmin } from './pages/LoginAdmin';
import { EditUser } from './pages/EditUser';
import { UserDetail } from './pages/UserDetail';
import Profile from './pages/Profile';
import Adminprofile from './pages/Adminprofile';
import Preloader from './components/Preloader';
import EventTicket from './pages/EventTicket';
import Venderscanner from './pages/VenderScanner';
import Matches from './components/Matches';

export function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    console.log('App: Starting loading simulation...');
    const timer = setTimeout(() => {
      setLoading(false);
      console.log('App: Loading complete.');
    }, 3000);

    return () => {
      clearTimeout(timer);
      console.log('App: Loading simulation cancelled.');
    };
  }, []);

  return (
    <BrowserRouter>
      <div className={loading ? 'preloading' : 'loaded'}>
        {loading && <Preloader />}
        <div id="content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/matches" element={<Matches />} />
            <Route path="/login-admin-vendor" element={<LoginAdmin />} />
            <Route path="/dashboard/*" element={<PrivateRoute element={DashboardV2} requiredRole={[ 'admin' , 'tournament organiser' ]} />} />
            <Route path="/allusers" element={<PrivateRoute element={AllUsers} requiredRole="admin" />} />
            <Route path="/reports" element={<PrivateRoute element={Reports} requiredRole="admin" />} />
            <Route path="/registration" element={<PrivateRoute element={Registration} requiredRole="admin" />} />
            <Route path="/mainregister" element={<PrivateRoute element={MainRegister} requiredRole="admin" />} />
            <Route path="/juice-vendor" element={<PrivateRoute element={JuiceScanner} requiredRole='juice vendor' />} />
            <Route path="/adminprofile" element={<PrivateRoute element={Adminprofile} requiredRole="admin" />} />
            <Route path="/edit-user/:id" element={<PrivateRoute element={EditUser} requiredRole="admin" />} />
            <Route path="/view-user/:id" element={<PrivateRoute element={UserDetail} requiredRole="admin" />} />
            <Route path="/profile" element={<PrivateRoute element={Profile} requiredRole="user" />} />
            <Route path="/event-ticket" element={<PrivateRoute element={EventTicket} requiredRole={['player', 'tournament organiser', 'tournament official', 'team manager', 'state secretary', 'coach', 'tournament doctor', 'physio', 'support staff', 'others']} />} />
            <Route path="/vendor" element={<PrivateRoute element={Venderscanner} requiredRole='vendor' />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
