import { useState, useEffect, useCallback } from 'react';
import { doc, setDoc, getDocs, getDoc, collection, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';

const useQrScanner = () => {
  const [error, setError] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [timings, setTimings] = useState(null); // Initialize timings as null initially

  useEffect(() => {
    const fetchTimings = async () => {
      try {
        const timingsDocRef = doc(collection(db, 'config'), 'timings'); // Adjusting to fetch 'timing' document
        const timingsDocSnapshot = await getDoc(timingsDocRef);

        if (timingsDocSnapshot.exists()) {
          const timingsData = timingsDocSnapshot.data();
          console.log('Fetched timings data:', timingsData); // Debugging
          if (timingsData) {
            setTimings(timingsData);
            console.log('Timings set:', timingsData); // Debugging
          } else {
            console.warn('No timings data found in the document.'); // Debugging
            setError('No timings data found in the document.');
            toast.error('No timings data found in the document.');
          }
        } else {
          console.warn('Timings document does not exist.'); // Debugging
          setError('Timings document does not exist.');
          toast.error('Timings document does not exist.');
        }
      } catch (error) {
        console.error('Error fetching timings from config:', error);
        toast.error('Error fetching timings from config.');
      }
    };

    const checkCameraAvailability = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAvailable(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        setCameraAvailable(false);
        setError('Camera is not available.');
        toast.error('Camera is not available.');
      }
    };

    fetchTimings();
    checkCameraAvailability();
  }, []);

  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const isWithinTimeRange = useCallback((hours, minutes, start, end) => {
    const currentTime = hours * 60 + minutes;
    const startTime = parseTimeString(start);
    const endTime = parseTimeString(end);
    return currentTime >= startTime && currentTime <= endTime;
  }, []);

  const determineCollectionName = useCallback(
    (hours, minutes) => {
      if (!timings) {
        console.warn('Timings have not been loaded yet.');
        return '';
      }
      const { morning, afternoon, evening } = timings;
      if (isWithinTimeRange(hours, minutes, morning.start, morning.end)) {
        return 'morning';
      } else if (isWithinTimeRange(hours, minutes, afternoon.start, afternoon.end)) {
        return 'afternoon';
      } else if (isWithinTimeRange(hours, minutes, evening.start, evening.end)) {
        return 'evening';
      } else {
        return '';
      }
    },
    [isWithinTimeRange, timings]
  );

  const handleVerification = useCallback(
    async (data) => {
      if (!data) {
        console.error('No scan data available');
        setError('No scan data available.');
        toast.error('No scan data available.');
        setIsScanning(false);
        return;
      }

      if (!timings) {
        console.error('Timings are not loaded yet.');
        toast.error('Timings are not loaded yet.');
        setIsScanning(false);
        return;
      }

      setIsVerifying(true);
      try {
        const now = new Date();
        const todayDate = now.toISOString().split('T')[0];
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const hourString = hours.toString().padStart(2, '0');

        const collectionName = determineCollectionName(hours, minutes);
        console.log(`Current collection name determined: ${collectionName}`);

        if (!collectionName) {
          console.warn("Counter hasn't started yet.");
          toast.error("Counter hasn't started yet.");
          setIsScanning(false);
          setIsVerifying(false);
          return;
        }

        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('mobile', '==', data.mobile));
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          console.error('No matching user found');
          setError('No matching user found.');
          toast.error('No matching user found.');
          return;
        }

        const userData = userSnapshot.docs[0].data();
        const userRole = userData.role;
        setUserDetails(userData);
        console.log('User role:', userRole);

        if (userRole !== 'tournament organiser') {
          const timeSpecificRef = collection(db, collectionName);
          const q = query(
            timeSpecificRef,
            where('mobile', '==', data.mobile),
            where('date', '==', todayDate)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            console.error('QR code already scanned today.');
            setError('This QR code has already been scanned today.');
            toast.error('This QR code has already been scanned today.');
            return;
          }
        }

        const newDocId =
          userRole === 'tournament organiser'
            ? `${data.mobile}_${todayDate}_${hourString}_${minutes}_${seconds}`
            : `${data.mobile}_${todayDate}_${hourString}`;

        const generalDocRef = doc(db, 'scanned', newDocId);
        const timeSpecificDocRef = doc(db, collectionName, newDocId);

        await setDoc(
          generalDocRef,
          {
            mobile: data.mobile,
            date: todayDate,
            lastScannedTime: Timestamp.fromDate(now),
          },
          { merge: true }
        );

        await setDoc(
          timeSpecificDocRef,
          {
            mobile: data.mobile,
            date: todayDate,
            lastScannedTime: Timestamp.fromDate(now),
          },
          { merge: true }
        );

        console.log('User verified successfully!');
        setIsModalOpen(true);
      } catch (error) {
        console.error('Error storing data:', error);
        setError(`Error storing data: ${error.message}`);
        toast.error(`Error storing data: ${error.message}`);
      } finally {
        setIsScanning(false);
        setIsVerifying(false);
      }
    },
    [determineCollectionName, timings]
  );

  const handleScan = useCallback(
    (data) => {
      if (data && data.length > 0) {
        try {
          const rawValue = data[0]?.rawValue;
          if (rawValue) {
            const scannedData = JSON.parse(rawValue);
            handleVerification(scannedData);
          } else {
            throw new Error('No raw value found in the scan data.');
          }
        } catch (error) {
          console.error('Error parsing QR code data:', error);
          setError('Error parsing QR code data.');
          toast.error('Error parsing QR code data.');
          setIsScanning(false);
        }
      } else {
        console.error('No data found from the QR scan.');
        setError('No data found from the QR scan.');
        toast.error('No data found from the QR scan.');
        setIsScanning(false);
      }
    },
    [handleVerification]
  );

  const handleScanNowClick = useCallback(() => {
    setIsScanning(true);
    setError('');
  }, []);

  const handleError = useCallback((message) => {
    setError(message);
    toast.error(message);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return {
    error,
    isScanning,
    cameraAvailable,
    isVerifying,
    isModalOpen,
    userDetails,
    closeModal,
    handleScan,
    handleError,
    handleScanNowClick,
    handleVerification,
  };
};

export default useQrScanner;
