import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Profile = () => {
  const location = useLocation();
  const userData = location.state?.userData;
  const qrRef = useRef(null);

  if (!userData) {
    return <div>No user data found.</div>;
  }

  const handleDownloadPDF = () => {
    const qrElement = qrRef.current;

    html2canvas(qrElement, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190; // Adjust image width according to your need
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const pdfHeight = Math.max(imgHeight, 297); // A4 paper height in mm

      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('ticket.pdf');
    });
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print QR Code</title></head><body>');
    printWindow.document.write('<h1>QR Code</h1>');

    const qrCanvas = qrRef.current.querySelector('canvas');
    if (qrCanvas) {
      printWindow.document.write('<img src="' + qrCanvas.toDataURL('image/png') + '" style="width:100%"/>');
    }

    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <div className='lg:w-full'>
      <div className="relative bg-blue-900 md:pt-32 pb-32 pt-12 mr-0 pl-0">
        <div className="pl-4 md:pl-10 mx-auto w-full">
          <div>
            <h1 className="text-white text-3xl">Welcome {userData.firstname + ' ' + userData.lastname}</h1>
            <p className="text-white mt-2">This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks.</p>
          </div>
        </div>
      </div>

      <div className="px-4 md:px-10 mx-auto w-full -m-20 mt-2">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-96 mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-4 flex justify-center bg-white">
                    <img
                      alt="..."
                      src={userData.photo}
                      className="shadow-xl rounded-full align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 w-40 h-40 bg-gray-700"
                    />
                  </div>

                  <div className="w-full px-4 text-center mt-20 h-auto">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8 mt-2">
                      {userData.qrData ? (
                        <div ref={qrRef} style={{ margin: '20px' }}>
                          <QRCode value={userData.qrData} size={150} /> {/* Increased QR code size */}
                        </div>
                      ) : (
                        <div>No QR data available</div>
                      )}
                    </div>
                    <button onClick={handleDownloadPDF} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
                      Download PDF
                    </button>
                    <button onClick={handlePrint} className="mt-4 px-4 py-2 bg-green-500 text-white rounded ml-2">
                      Print Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="rounded-t bg-white mb-0 px-6 py-6">
                <div className="text-center flex justify-between">
                  <h6 className="text-xl font-bold">My account</h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    User Information
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          First name
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.firstname}
                          placeholder="First name"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.lastname}
                          placeholder="Last name"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Mobile No.
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.mobile}
                          placeholder="Mobile No."
                          maxLength={10}
                          minLength={10}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.email}
                          placeholder="Email address"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          DOB
                        </label>
                        <input
                          type="date"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.date}
                          placeholder=""
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Gender
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.gender}
                          placeholder="Gender"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Blood Group
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.bloodgroup}
                          placeholder="Blood group"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          State
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          defaultValue={userData.state}
                          placeholder="State"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
