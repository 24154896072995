import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { auth } from "../firebase";

const Header = ({ userData }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Clear local storage
      localStorage.removeItem("userData");
      localStorage.removeItem("someOtherData");
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <header className="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-[48] w-full bg-white border-b border-gray-200 text-sm py-2.5 lg:ps-[260px] dark:bg-neutral-800 dark:border-neutral-700">
      <nav className="px-4 sm:px-6 flex basis-full items-center w-full mx-auto">
        <div className="me-5 lg:me-0">
          <label className="w-fit flex-none rounded-md text-[1em] sm:text-lg leading-[1.2em] inline-block font-normal focus:outline-none focus:opacity-80">
            {/* <img className='w-28 h-auto' src='https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FWZlogo.f5c888fccfad9554ea49.png?alt=media&token=2ab48c7d-6726-4133-abf2-64a1b95959b2' alt='logo' /> */}
            Yonex-Sunrise West Zone Interstate Badminton Championship, Anand 2024
          </label>
        </div>

        <div className="w-[300px] hidden lg:flex items-center justify-center">
          <div className=" grid grid-cols-3 gap-2 md:gap-4 items-center">
            <div className="flex flex-col h-full justify-center items-center">
              <div className=" flex items-center justify-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FScreenshot%202024-08-26%20at%2017.05.03.png?alt=media&token=22533c14-fc6c-4534-b041-a1145de574f2"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col h-full justify-center items-center">
              <div className=" flex items-center justify-center">
                <img
                  src="https://adba.in/images/channels4_profile.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col h-full justify-center items-center">
              <div className="flex items-center justify-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FIMG-20240830-WA0004.jpg?alt=media&token=1641963e-1505-4c49-aa56-6683ad29a25f"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-end ms-auto md:justify-end gap-x-1 md:gap-x-3">
          <div className="hidden">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                <svg
                  className="shrink-0 size-4 text-gray-400 dark:text-white/60"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="11" cy="11" r="8" />
                  <path d="m21 21-4.3-4.3" />
                </svg>
              </div>
              <input
                type="text"
                className="py-2 ps-10 pe-16 block w-full bg-white border-gray-200 rounded-lg text-sm focus:outline-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                placeholder="Search"
              />
              <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-1">
                <button
                  type="button"
                  className="inline-flex shrink-0 justify-center items-center size-6 rounded-full text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-500 dark:hover:text-blue-500 dark:focus:text-blue-500"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="m15 9-6 6" />
                    <path d="m9 9 6 6" />
                  </svg>
                </button>
              </div>
              <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-3 text-gray-400">
                <svg
                  className="shrink-0 size-3 text-gray-400 dark:text-white/60"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
                </svg>
                <span className="mx-1">
                  <svg
                    className="shrink-0 size-3 text-gray-400 dark:text-white/60"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14" />
                    <path d="M12 5v14" />
                  </svg>
                </span>
                <span className="text-xs">/</span>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-end gap-1">
            <button
              type="button"
              className="hidden size-[38px] relative inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            >
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="m21 21-4.3-4.3" />
              </svg>
              <span className="sr-only">Search</span>
            </button>

            <button
              type="button"
              className="hidden size-[38px] relative inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            >
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
              </svg>
              <span className="sr-only">Notifications</span>
            </button>

            <button
              type="button"
              className="hidden size-[38px] relative inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            >
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
              </svg>
              <span className="sr-only">Activity</span>
            </button>

            <div className=" relative inline-flex">
              <button
                type="button"
                className="size-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 focus:outline-none disabled:opacity-50 disabled:pointer-events-none dark:text-white"
                aria-haspopup="menu"
                aria-expanded="false"
                aria-label="Dropdown"
              >
                <img
                  className="shrink-0 size-[38px] rounded-full"
                  src={
                    userData.photo
                      ? userData.photo
                      : "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                  }
                  alt="Avatar"
                />
              </button>

              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 border border-gray-100 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="hs-dropdown-account"
              >
                <div className="py-3 px-5 bg-gray-100 rounded-t-lg dark:bg-neutral-700">
                  <p className="text-sm text-gray-500 dark:text-neutral-500">
                    Signed in as
                  </p>
                  <p className="text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {userData.email}
                  </p>
                </div>
                <div className="p-1.5 space-y-0.5">
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      `flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300 ${
                        isActive ? "bg-gray-100 dark:bg-neutral-700" : ""
                      }`
                    }
                  >
                    <svg
                      className="shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                      <polyline points="9 22 9 12 15 12 15 22" />
                    </svg>
                    Dashboard
                  </NavLink>

                  <NavLink
                    to="/admin-scanner"
                    className={({ isActive }) =>
                      `flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300 ${
                        isActive ? "bg-gray-100 dark:bg-neutral-700" : ""
                      }`
                    }
                  >
                    <svg
                      className="shrink-0 size-4"
                      id="Layer_2"
                      viewBox="0 0 35 35"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M25.83,31.15H9.17a5.33,5.33,0,0,1-5.32-5.32V9.17A5.33,5.33,0,0,1,9.17,3.85H25.83a5.33,5.33,0,0,1,5.32,5.32V25.83A5.33,5.33,0,0,1,25.83,31.15ZM9.17,6.35A2.83,2.83,0,0,0,6.35,9.17V25.83a2.83,2.83,0,0,0,2.82,2.82H25.83a2.83,2.83,0,0,0,2.82-2.82V9.17a2.83,2.83,0,0,0-2.82-2.82Z" />
                      <path d="M33.5,18.75H1.5a1.25,1.25,0,0,1,0-2.5h32a1.25,1.25,0,0,1,0,2.5Z" />
                    </svg>
                    Scanner
                  </NavLink>
                  <a
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                    href="#"
                    onClick={handleLogout}
                  >
                    <svg
                      id="Layer_1"
                      className="shrink-0 size-4"
                      style={{ enableBackground: "new 0 0 24 24" }}
                      version="1.1"
                      viewBox="0 0 24 24"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <style type="text/css">
                        {`
        .st0{fill:none;stroke:#000000;stroke-width:1.6724;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st1{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st2{fill:none;stroke:#000000;stroke-width:1.5;stroke-linejoin:round;stroke-miterlimit:10;}
      `}
                      </style>
                      <g>
                        <g>
                          <line
                            className="st1"
                            x1="7.6"
                            x2="22"
                            y1="12"
                            y2="12"
                          />
                        </g>
                        <g>
                          <path
                            className="st1"
                            d="M11.9,0.8H4.5C3.1,0.8,2,1.9,2,3.2v17.5c0,1.4,1.1,2.5,2.5,2.5h7.4"
                          />
                        </g>
                        <polyline
                          className="st1"
                          points="18.2,8.2 22,12 18.2,15.8"
                        />
                      </g>
                    </svg>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
