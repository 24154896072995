// src/userService.js
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import bcrypt from "bcryptjs"; // Make sure bcryptjs is installed

/**
 * Function to update user's password in Firestore
 * @param {string} mobile - User's mobile number
 * @param {string} newPassword - The new password to be saved
 */
export const updateUserPassword = async (mobile, newPassword) => {
  try {
    // Fetch the user document by mobile
    const userRef = doc(db, "users", mobile);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      // Hash the new password
      const hashedPassword = await bcrypt.hash(newPassword, 10);

      // Update the password field in Firestore
      await updateDoc(userRef, { password: hashedPassword, isFirstLogin: false });

      return { success: true };
    } else {
      console.error("User does not exist");
      return { success: false, message: "User does not exist" };
    }
  } catch (error) {
    console.error("Error updating password:", error);
    return { success: false, message: error.message };
  }
};
