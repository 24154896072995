import React from 'react';

const RegisterInfo = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-[80] overflow-x-hidden overflow-y-auto bg-black bg-opacity-50">
      <div className="relative m-3 sm:max-w-lg sm:w-full sm:mx-auto bg-white rounded-xl shadow-lg">
        <div className="absolute top-2 end-2">
          <button
            type="button"
            className="inline-flex justify-center items-center rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
            aria-label="Close"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-4 sm:p-10 text-center">
          <span className="mb-4 inline-flex justify-center items-center w-12 h-12 rounded-full border-4 border-green-50 bg-green-100 text-green-500">
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
            </svg>
          </span>

          <h3 id="register-info-title" className="mb-2 text-xl font-bold text-gray-800">
            Please contact admin to register your account!
          </h3>
        {/*  <p className="text-gray-500">
            You can see the progress of your task in your{' '}
            <a
              className="text-blue-600 hover:underline"
              href="#"
            >
              personal account.
            </a>{' '}
            You will be notified of its completion.
          </p>

          */}
          <div className="mt-6 flex justify-center gap-x-4">
            <button
              type="button"
              className="py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
              onClick={onClose}
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterInfo;
