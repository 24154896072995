import React from "react";
import '../assets/css/matchdetails.css';

// Default photo URL
const DEFAULT_PHOTO_URL =
  "https://ik.imagekit.io/gunadev/Badminton/2441904.jpg?updatedAt=1724328594825"; // Replace with the actual path to your default photo

const formatScore = (score) => {
  // Example formatting logic, you can customize this based on your needs
  return score.padStart(2, "0"); // Ensures score has two digits, e.g., 7 -> "07"
};

const MatchDetailModal = ({ matchData, onClose }) => {
  const { playerInfo, ongoingSetNumber, scores, createdAt, completed } =
    matchData;

  const { player1, player2 } = playerInfo;
  const { player1Photo = DEFAULT_PHOTO_URL, player2Photo = DEFAULT_PHOTO_URL } =
    matchData; // Default photo fallback

  // Calculate total scores
  const { player1Total, player2Total } = scores.reduce(
    (acc, set) => {
      acc.player1Total += set.player1Score;
      acc.player2Total += set.player2Score;
      return acc;
    },
    { player1Total: 0, player2Total: 0 }
  );

  // Determine winner
  const winner = player1Total > player2Total ? player1 : player2;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-2">
      <div className="bg-white w-full max-w-2xl rounded-lg shadow-lg pb-6 overflow-hidden">
        <div className="flex justify-between items-center mb-4 bg-gray-100 p-6">
          <h2 className="text-xl font-normal">
            {player1} vs {player2}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-red-500"
          >
            <svg
              className="shrink-0 size-4"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <style>{`
        .cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px;
        }
      `}</style>
              </defs>
              <g id="cross">
                <line
                  className="cls-1"
                  x1="7"
                  x2="25"
                  y1="7"
                  y2="25"
                  stroke="currentColor"
                />
                <line
                  className="cls-1"
                  x1="7"
                  x2="25"
                  y1="25"
                  y2="7"
                  stroke="currentColor"
                />
              </g>
            </svg>
          </button>
        </div>

        {/* Match Details */}
        <div className="text-sm text-gray-500 mb-4 px-6">
          {createdAt && (
            <p>Match Created: {new Date(createdAt).toLocaleString()}</p>
          )}
        </div>
        {!completed && (
          <div className="live w-full flex flex-col items-center justify-center my-4 px-6">
            <label className="text-red-500">
              <span className="text-red-500 animate-ping">⦿</span> Live
            </label>
            {ongoingSetNumber && (
              <p className="text-gray-600 text-[.8em]">
                Set: {ongoingSetNumber}
              </p>
            )}
          </div>
        )}

        {completed && (
          <div className="completed w-full flex justify-center my-4 px-6">
            <label className="text-green-500">Completed</label>
          </div>
        )}

        {/* Box Score */}
        <div className="grid grid-cols-4 md:grid-cols-3 gap-4 mb-6 mt-4 px-6">
          <div className="flex flex-col items-center">
            <img
              src={player1Photo}
              alt={player1}
              className="image__size rounded-full mb-2 border border-gray-100"
            />
            <span>{player1}</span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-2 md:col-span-1">
            {scores.map((set, index) => (
              <div
                key={index}
                className="w-full grid grid-cols-3 divide-x items-center gap-x-4"
              >
                <div className="flex justify-center">
                  <span className="text-lg font-semibold ">
                    {formatScore(set.player1Score)}
                  </span>
                </div>
                <div className="flex justify-center">
                  <span className="text-sm font-medium ">Set {index + 1}</span>
                </div>
                <div className="flex justify-center">
                  <span className="text-lg font-semibold">
                    {formatScore(set.player2Score)}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center">
            <img
              src={player2Photo}
              alt={player2}
              className="image__size rounded-full mb-2 border border-gray-100"
            />
            <span>{player2}</span>
          </div>
        </div>

        {/* Winner */}
        {completed && (
          <div className="text-center text-xl px-6">
            <p className="">Winner : {winner}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchDetailModal;
