import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FaTachometerAlt, FaUsers, FaChartLine, FaUserPlus, FaQrcode, FaSignOutAlt } from 'react-icons/fa';
import Profile from '../Profile';
import { auth } from '../../firebase';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const hiddenPaths = ['/', '/mainregister', '/Profile', '/UserProfile', '/Register'];

  const shouldHideSidebar = hiddenPaths.includes(location.pathname);

  useEffect(() => {
    const handleResize = () => {
      // Handle resize event
      console.log('Window resized');
    };

    if (!shouldHideSidebar) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [shouldHideSidebar]);

  if (shouldHideSidebar) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Clear local storage
      localStorage.removeItem('userData');
      localStorage.removeItem('someOtherData'); // Add other local storage items you want to clear
      // Optionally, clear cookies
      // Cookies.remove('user');
      // Redirect to login page
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div>
      <Profile toggleDrawer={toggleDrawer} className="justify-end items-end" />

      {/* Drawer component */}
      <div
        id="drawer-navigation"
        className={`fixed top-0 left-0 z-40 sidebar w-64 h-screen p-4 overflow-y-auto border-gray-300 rounded-lg shadow-sm transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} bg-white`}
        tabIndex="-1"
        aria-labelledby="drawer-navigation-label"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FWhatsApp%20Image%202024-07-21%20at%2016.04.29_abaf3be5.jpg?alt=media&token=582e345b-4fc4-4dd2-a934-c8c3cb37f2fb"
          className="h-12 w-40 ml-2"
          alt="Logo"
        />
        <button
          type="button"
          onClick={toggleDrawer}
          aria-controls="drawer-navigation"
          className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-1 font-medium divide-y">
            <li>
              <a
                href="/dashboard"
                className="flex items-center p-3 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group lg:text-xl"
              >
                <FaTachometerAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href="/allusers"
                className="flex items-center p-3 lg:text-xl text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group"
              >
                <FaUsers className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">All Users</span>
              </a>
            </li>
            <li>
              <a
                href="/reports"
                className="flex items-center p-3 lg:text-xl text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group"
              >
                <FaChartLine className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">Reports</span>
              </a>
            </li>
            <li>
              <a
                href="/registration"
                className="flex items-center p-3 lg:text-xl text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group"
              >
                <FaUserPlus className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">Registration</span>
              </a>
            </li>
            <li>
              <a
                href="/scanner"
                className="flex items-center p-3 lg:text-xl text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group"
              >
                <FaQrcode className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">Scanner</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={handleLogout}
                className="flex items-center p-3 lg:text-xl text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300 group"
              >
                <FaSignOutAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap dark:group-hover:text-black">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
