import React, { useState, useEffect, useRef } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebase";
import QRCode from "qrcode.react";
import ep from "../assets/ep.png";
import "../assets/css/eventticket.css";
import { useLocation, useNavigate } from "react-router-dom";
import UserHeader from "../components/UserHeader";
import { updateUserPassword } from "../utils/PasswordService";
import PasswordChangeModal from "../Model/PasswordChange";
import { ToastContainer } from 'react-toastify';

const EventCard = () => {
  const [userData, setUserData] = useState(null);
  const [qrValue, setQrValue] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const qrRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = location.state?.userData;
    if (data) {
      setUserData(data);
      setQrValue(JSON.stringify(data));

      if (data.isFirstLogin) {
        setShowPasswordModal(true);
      }
    }
  }, [location]);

  const handlePasswordSave = async (newPassword) => {
    if (!userData) return;
    
    const result = await updateUserPassword(userData.mobile, newPassword);

    if (result.success) {
      setShowPasswordModal(false);
      console.log("Password updated successfully.");
    } else {
      console.error("Failed to update password:", result.message);
    }
  };

  const handleCloseModal = () => {
    setShowPasswordModal(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <UserHeader userData={userData} handleLogout={handleLogout} />
      <div
        className="relative py-[1em] flex flex-col items-center justify-center min-h-[100svh] md:min-h-[95vh] overflow-hidden bg-center bg-cover"
        style={{
          backgroundImage:
            "url(https://ik.imagekit.io/gunadev/Badminton/close-up-athlete-playing-tennis_23-2150845514.jpg_t=st=1723499022~exp=1723502622~hmac=198d037f3469631aab2d8e57f64c8d3814ebdde1ad3f27a0e768c4e24362adeb&w=1800?updatedAt=1723499243616)",
        }}
      >
        <div className="absolute bg-blue-900 opacity-80 inset-0 z-0"></div>
        <div className="max-w-md w-full h-fit mx-auto z-10 bg-blue-900 rounded-3xl">
          <div className="flex flex-col">
            <div className="bg-white relative drop-shadow-2xl rounded-3xl p-4 m-4">
              <div className="flex-none sm:flex">
                <div className="flex-auto justify-evenly">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between">
                      <span className="mr-2 rounded-full bg-white hidden">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FScreenshot%202024-08-26%20at%2017.05.03.png?alt=media&token=22533c14-fc6c-4534-b041-a1145de574f2"
                          className="w-[8em] p-1"
                          alt="icon"
                        />
                      </span>
                      <h2 className="font-medium">
                        Yonex-Sunrise West Zone Interstate Badminton
                        Championship, Anand 2024
                      </h2>
                    </div>
                  </div>

                  <div className="border-b border-dashed border-b-2 my-5 pt-5">
                    <div className="absolute rounded-full w-5 h-5 bg-blue-900 -mt-2 -left-2"></div>
                    <div className="absolute rounded-full w-5 h-5 bg-blue-900 -mt-2 -right-2"></div>
                  </div>
                  <div className="flex items-center mb-5 p-5 text-sm">
                    <div className="flex flex-col">
                      <span className="text-sm">Name</span>
                      <div className="font-semibold">
                        {userData.firstname} {userData.lastname}
                      </div>
                    </div>
                    <div className="ml-auto text-blue-800">
                      <img
                        className="shrink-0 size-[8em]"
                        src={userData.photo}
                        alt="Avatar"
                      />
                    </div>
                    <div className="flex flex-col ml-auto">
                      <span className="text-sm">Gender</span>
                      <div className="font-semibold">{userData.gender}</div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mb-4 px-2">
                    <div className="flex flex-col text-sm">
                      <span className="text-center">Mobile</span>
                      <div className="font-semibold text-center">
                        {userData.mobile}
                      </div>
                    </div>
                    <div className="flex flex-col mx-auto text-sm">
                      <span className="text-center">State</span>
                      <div className="font-semibold text-center">
                        {userData.state}
                      </div>
                    </div>
                    <div className="flex flex-col text-sm">
                      <span className="text-center">Role</span>
                      <div className="font-semibold capitalize text-center">
                        {userData.role}
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-dashed border-b-2 my-5 pt-5">
                    <div className="absolute rounded-full w-5 h-5 bg-blue-900 -mt-2 -left-2"></div>
                    <div className="absolute rounded-full w-5 h-5 bg-blue-900 -mt-2 -right-2"></div>
                  </div>
                  <div className="flex flex-col pt-5 justify-center text-sm">
                    <h6 className="font-bold text-center">QR CODE</h6>
                  </div>
                  <div className="">
                    {userData.qrData ? (
                      <div ref={qrRef} className="flex justify-center mt-4">
                        <QRCode value={userData.qrData} />{" "}
                      </div>
                    ) : (
                      <div>No QR data available</div>
                    )}
                  </div>
                  <div className="flex flex-col w-full items-center justify-center my-4 py-2 border-t-[1px] border-gray-100">
                    <label className="font-semibold">Affiliated To</label>
                    <div className="mt-1 grid grid-cols-3 gap-2 items-center">
                      <div className="flex flex-col h-full justify-center items-center">
                      <div className="flex-1 flex items-center justify-center">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FScreenshot%202024-08-26%20at%2017.05.03.png?alt=media&token=22533c14-fc6c-4534-b041-a1145de574f2"
                          alt=""
                          className="w-[50%]"
                        />
                        </div>
                        <label className="text-[0.8em] text-center">
                          Gujarat Badminton Association
                        </label>
                      </div>
                      <div className="flex flex-col h-full justify-center items-center">
                        <div className="flex-1 flex items-center justify-center">
                        <img
                          src="https://adba.in/images/channels4_profile.jpg"
                          alt=""
                          className="w-[50%]"
                        />
                        </div>
                        <label className="text-[0.8em] text-center">
                          Badminton Association of India
                        </label>
                      </div>
                      <div className="flex flex-col h-full justify-center items-center">
                      <div className="flex-1 flex items-center justify-center">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FIMG-20240830-WA0004.jpg?alt=media&token=1641963e-1505-4c49-aa56-6683ad29a25f"
                          alt=""
                          className="w-[50%]"
                        />
                        </div>
                        <label className="text-[0.8em] text-center">
                          Anand District Badminton Association
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PasswordChangeModal
        isOpen={showPasswordModal}
        onClose={handleCloseModal}
        onSubmit={handlePasswordSave}
      />
      </div>
      <ToastContainer />
    </>
  );
};

export default EventCard;
