import React, { useState, useEffect } from 'react';
import logo from '../assets/WZlogo.png';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase'; // Ensure db is imported
import { doc, getDoc } from 'firebase/firestore';

export const Profile = ({ toggleDrawer }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  if (!userData) return null;

  return (
    <nav className="bg-white border-gray-200 dark:bg-white border rounded-lg shadow-md fixed top-0 left-0 right-0 z-auto">
      <div className="flex items-center justify-between p-2 3xl:h-20 lg:h-14">
        <div className="flex gap-x-1">
          <button
            className="text-black focus:ring-2 font-medium rounded-lg text-sm px-5 py-2.5 3xl:text-2xl xl:text-2xl lg:text-lg"
            type="button"
            onClick={toggleDrawer}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <a href="" className="flex space-x-2">
            <img
              src={logo}
              className="h-14 hidden md:block md:text-base lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-5xl"
              alt="Westzone logo"
            />
          </a>
        </div>

        {/* User Menu */}
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {/* User Profile Button */}
          <button
            type="button"
            className="flex text-sm 3xl:space-x-3 xl:space-x-2 lg-space-x-2"
            id="user-menu-button"
          >
            {userData ? (
              <>
                <img
                  className="w-12 h-12 3xl:h-14 3xl:w-14 lg:w-11 lg:h-11 rounded-full bg-gray-800 md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  src={userData.photo || '/docs/images/people/profile-picture-3.jpg'}
                  alt="Profile"
                />
                <div>
                  <p className="font-bold text-xl md:text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-2xl">
                    {userData.firstname+ ' '+ userData.lastname|| 'Admin name'}
                  </p>
                  <small className="text-gray-500 md:text-sm lg:text-sm xl:text-sm 2xl:text-base 3xl:text-lg">
                    {userData.email || 'admin@gmail.com'}
                  </small>
                </div>
              </>
            ) : (
              <div className="flex items-center">
                <img
                  className="w-12 h-12 3xl:h-14 3xl:w-14 lg:w-11 lg:h-11 rounded-full bg-gray-800 md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  src="/docs/images/people/profile-picture-3.jpg"
                  alt="Profile"
                />
                <div>
                  <p className="font-bold text-xl md:text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-2xl">
                    User Name
                  </p>
                  <small className="text-gray-500 md:text-sm lg:text-sm xl:text-sm 2xl:text-base 3xl:text-lg">
                    user@example.com
                  </small>
                </div>
              </div>
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Profile;
