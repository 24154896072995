

import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar/Sidebar';

import { FaTimes } from 'react-icons/fa';
import { collection, getDocs, deleteDoc, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


// Modal component to display user details
const UserDetailsModal = ({ userDetails, onClose }) => {
  
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-50 ">
      <div className="bg-white p-4 rounded shadow-lg text-center w-full max-w-md ">
        <div className="flex justify-end">
          <button className="text-gray-600 hover:text-gray-800" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">User Details</h2>
        <div className="text-left">
          <p><strong>Name:</strong> {userDetails.firstname} {userDetails.lastname}</p>
          <p><strong>Email:</strong> {userDetails.email}</p>
          <p><strong>Mobile:</strong> {userDetails.mobile}</p>
          <p><strong>Status:</strong> {userDetails.status === 'inactive' ? 'Inactive' : 'Active'}</p>
        </div>
      </div>
    </div>
  );
};



// Modal component for editing user details
const EditUserModal = ({ userDetails, onClose, onUpdate }) => {
  const [editedUser, setEditedUser] = useState({ ...userDetails });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleUpdate = async () => {
    try {
      // Update user data in the users collection
      await updateDoc(doc(db, 'users', userDetails.id), editedUser);

      // If the user is promoted to admin, add them to the admin collection
      if (editedUser.role === 'admin' && userDetails.role !== 'admin') {
        await setDoc(doc(db, 'admin', userDetails.id), editedUser);
      }

      // If the user is demoted from admin to user, remove them from the admin collection
      if (editedUser.role === 'user' && userDetails.role === 'admin') {
        await deleteDoc(doc(db, 'admin', userDetails.id));
      }

      // If the user is promoted to vender, add them to the vender collection
      if (editedUser.role === 'vender' && userDetails.role !== 'vender') {
        await setDoc(doc(db, 'vender', userDetails.id), editedUser);
      }

      // If the user is demoted from vender to user or admin, remove them from the vender collection
      if ((editedUser.role === 'user' || editedUser.role === 'admin') && userDetails.role === 'vender') {
        await deleteDoc(doc(db, 'vender', userDetails.id));
      }

      onUpdate(editedUser);
      onClose();
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-50" role="dialog" aria-labelledby="edit-user-title" aria-modal="true">
      <div className="bg-white p-4 rounded shadow-lg text-center w-full max-w-md">
        <div className="flex justify-end">
          <button aria-label="Close" className="text-gray-600 hover:text-gray-800" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <h2 id="edit-user-title" className="text-xl font-bold mb-4">Edit User</h2>
        <div className="text-left">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">First Name</label>
            <input type="text" name="firstname" value={editedUser.firstname} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Last Name</label>
            <input type="text" name="lastname" value={editedUser.lastname} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" name="email" value={editedUser.email} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Mobile</label>
            <input type="text" name="mobile" value={editedUser.mobile} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Status</label>
            <select name="status" value={editedUser.status} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Role</label>
            <select name="role" value={editedUser.role} onChange={handleChange} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value="vender">Vender</option>
            </select>
          </div>
          {error && (
            <div className="bg-red-500 text-white p-2 mb-4 rounded">
              {error}
            </div>
          )}
          <button onClick={handleUpdate} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Update</button>
        </div>
      </div>
    </div>
  );
};


export const AllUsers = () => {
  const [users, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(6);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const usersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        usersList.sort((a, b) => b.timestamp - a.timestamp);
        setAllUsers(usersList);
        setFilteredUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter users based on searchQuery whenever it changes
    const filteredResults = users.filter(user => {
      const firstname = user.firstname?.toLowerCase() || '';
      const lastname = user.lastname?.toLowerCase() || '';
      const email = user.email?.toLowerCase() || '';
      const mobile = user.mobile?.toLowerCase() || '';
      
      return firstname.includes(searchQuery.toLowerCase()) ||
             lastname.includes(searchQuery.toLowerCase()) ||
             email.includes(searchQuery.toLowerCase())||
             mobile.includes(searchQuery.toLowerCase());
    });
    setFilteredUsers(filteredResults);
  }, [searchQuery, users]);

  const handleEditClick = (user) => {
    setUserDetails(user);
    setShowEditModal(true);
  };

  const handleViewDetails = async (id) => {
    try {
      const userDocRef = await getDoc(doc(db, 'users', id));
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        setUserDetails({ id: userDocSnap.id, ...userDocSnap.data() });
        setShowViewModal(true);
      } else {
        console.error('User not found');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const closeViewModal = () => {
    setShowViewModal(false);
    setUserDetails(null);
  };

  const handleUpdateUser = (updatedUser) => {
    setFilteredUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === updatedUser.id ? updatedUser : user
      )
    );
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setUserDetails(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Calculate the current slice of users to display
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = searchQuery === '' ? users.slice(indexOfFirstUser, indexOfLastUser) : filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Total number of pages
  const totalPages = Math.ceil((searchQuery === '' ? users.length : filteredUsers.length) / usersPerPage);

  // Function to navigate to the next page
  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  // Function to navigate to the previous page
  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  // Disable previous button when on the first page
  const isFirstPage = currentPage === 1;

  // Disable next button when on the last page
  const isLastPage = currentPage === totalPages;

  return (
    <div className="pt-10 pl-0 w-full flex flex-col items-center justify-center h-screen bg-gray-100 overflow-x-scroll">
      <Sidebar />
      <div className="max-w-4xl w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Users List</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="p-2 pl-10 border rounded"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
            </div>
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto border-gray-300 rounded-lg shadow-md mt-3">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile No</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentUsers.map((user, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img className="h-10 w-10 rounded-full" src={user.photo} alt={user.firstname || 'User'} />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{user.firstname} {user.lastname}</div>
                      <div className="text-sm text-gray-500">{user.name}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{user.mobile}</div>
                  <div className="text-sm text-gray-500">{user.department}</div>
                </td>
                <td className="py-2 px-4">
                  <span className={`inline-block w-2 h-2 mr-2 rounded-full ${user.status === 'inactive' ? 'bg-red-500' : 'bg-green-500'}`}></span>
                  {user.status === 'inactive' ? 'Inactive' : 'Active'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => { setUserDetails(user); setShowEditModal(true); }}>Edit</a>
                  <a href="#" className="ml-2 text-red-600 hover:text-red-900" onClick={() => handleViewDetails(user.id)}>View</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={prevPage}
            disabled={isFirstPage}
            className={`px-3 py-1 rounded ${isFirstPage ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {"<"}
          </button>
          <button
            onClick={nextPage}
            disabled={isLastPage}
            className={`px-3 py-1 rounded ${isLastPage ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {">"}
          </button>
        </div>
      </div>
      {showViewModal && <UserDetailsModal userDetails={userDetails} onClose={closeViewModal} />}
      {showEditModal && <EditUserModal userDetails={userDetails} onClose={closeEditModal} onUpdate={handleUpdateUser} />}
    </div>
  );
};



