import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary

const Profile = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        setUser(userDoc.data());
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

//   if (!user) return <div>Loading...</div>;

  return (
    <div className="pt-12 px-4 md:px-12">
      <nav className="fixed top-0 left-0 w-full bg-white shadow-md flex justify-end items-center p-4 z-10">
        <div className="flex items-center cursor-pointer">
          <img src="cb1.png" alt="Profile-Photo" className="w-10 h-10 rounded-full mr-2" />
          <div className="flex flex-col">
            <span className="font-bold">{user.firstName} {user.lastName}</span>
            <span>{user.email}</span>
          </div>
          <div className="relative ml-4">
            <i className="fa-solid fa-caret-down"></i>
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
              <a href="ticket.html" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Ticket</a>
              <button
                onClick={() => auth.signOut().then(() => navigate('/login'))}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className="mt-20 flex justify-center">
        <div className="bg-teal-400 text-white p-6 rounded-lg shadow-md max-w-lg w-full">
          <div className="flex flex-col items-center">
            <div className="mb-4">
              {/* <img src="img.JPG" alt="User Photo" className="w-64 h-64 rounded-full" /> */}
              <img src="img.JPG" alt="User-Photo" className="w-64 h-64 rounded-full"/>
            </div>
            <div className="text-center">
              <div className="mb-2"><strong>First Name:</strong> {user.firstName}</div>
              <div className="mb-2"><strong>Last Name:</strong> {user.lastName}</div>
              <div className="mb-2"><strong>Phone Number:</strong> {user.phoneNumber}</div>
              <div className="mb-2"><strong>Email:</strong> {user.email}</div>
              <div className="mb-2"><strong>Birthday:</strong> {user.birthday}</div>
              <div className="mb-2"><strong>Gender:</strong> {user.gender}</div>
              <div className="mb-2"><strong>Blood Group:</strong> {user.bloodGroup}</div>
              <div className="mb-2"><strong>State:</strong> {user.state}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
