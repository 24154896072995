import React, { useState, useRef, useEffect } from "react";

const UserHeader = ({ userData, handleLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-[48] w-full bg-white border-b border-gray-200 text-sm py-2.5 dark:bg-neutral-800 dark:border-neutral-700">
      <nav className="px-4 sm:px-6 flex basis-full items-center w-full mx-auto">
        <div className="me-5 lg:me-0 lg:hidden">
          <a
            className="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
            href="#"
          >
            <img
              className="w-28 h-auto"
              src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FWZlogo.f5c888fccfad9554ea49.png?alt=media&token=2ab48c7d-6726-4133-abf2-64a1b95959b2"
              alt="logo"
            />
          </a>
        </div>

        <div className="w-full flex items-center justify-end ms-auto md:justify-end lg:justify-between gap-x-1 md:gap-x-3">
        
          <div className="me-5 lg:me-0 hidden lg:block">
            <a
              className="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
              href="#"
            >
              <img
                className="w-28 h-auto"
                src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FWZlogo.f5c888fccfad9554ea49.png?alt=media&token=2ab48c7d-6726-4133-abf2-64a1b95959b2"
                alt="logo"
              />
            </a>
          </div>

          <div className="relative flex flex-row items-center justify-between gap-1">
            <button
              id="hs-dropdown-account"
              type="button"
              className="size-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 focus:outline-none disabled:opacity-50 disabled:pointer-events-none dark:text-white"
              aria-haspopup="menu"
              aria-expanded={isDropdownOpen}
              aria-label="Dropdown"
              onClick={toggleDropdown}
              ref={buttonRef}
            >
              <img
                className="shrink-0 size-[38px] rounded-full"
                src={userData.photo? userData.photo : 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80'}
                alt="Avatar"
              />
            </button>

            <div
              className={`absolute end-0 top-8 mt-2 w-60 bg-white shadow-lg rounded-lg border border-gray-100 dark:bg-neutral-800 dark:border-neutral-700 transition-opacity duration-300 ${
                isDropdownOpen ? "opacity-100 block" : "opacity-0 hidden"
              }`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="hs-dropdown-account"
              ref={dropdownRef}
            >
              <div className="py-3 px-5 bg-gray-100 rounded-t-lg dark:bg-neutral-700">
                <p className="text-sm text-gray-500 dark:text-neutral-500">
                  Signed in as
                </p>
                <p className="text-sm font-medium text-gray-800 dark:text-neutral-200">
                  {userData.email}
                </p>
              </div>
              <div className="p-1.5 space-y-0.5">
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                  href="#"
                  onClick={handleLogout}
                >
                  <svg
                    id="Layer_1"
                    className="shrink-0 size-4"
                    style={{ enableBackground: "new 0 0 24 24" }}
                    version="1.1"
                    viewBox="0 0 24 24"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <style type="text/css">
                      {`
                        .st0{fill:none;stroke:#000000;stroke-width:1.6724;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                        .st1{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                        .st2{fill:none;stroke:#000000;stroke-width:1.5;stroke-linejoin:round;stroke-miterlimit:10;}
                      `}
                    </style>
                    <g>
                      <g>
                        <line
                          className="st1"
                          x1="7.6"
                          x2="22"
                          y1="12"
                          y2="12"
                        />
                      </g>
                      <g>
                        <path
                          className="st1"
                          d="M11.9,0.8H4.5C3.1,0.8,2,1.9,2,3.2v17.5c0,1.4,1.1,2.5,2.5,2.5h7.4"
                        />
                      </g>
                      <polyline
                        className="st1"
                        points="18.2,8.2 22,12 18.2,15.8"
                      />
                    </g>
                  </svg>
                  Logout
                </a>
              </div>
            </div>
          </div>

        </div>
      </nav>
    </header>
  );
};

export default UserHeader;
