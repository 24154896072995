import React from 'react';
import Header from '../components/Header';
import SidebarV2 from '../components/SidebarV2';
import BottomMenu from '../components/BottomMenu';
import { useLocation } from 'react-router-dom';

const DashboardLayout = ({ children, userData }) => {
  const location = useLocation();
  

  return (
    <section className='bg-gray-50 dark:bg-neutral-900'>
      <Header userData={userData}/>
      <SidebarV2/>
      <div className='flex flex-col md:flex-row'>
        <main className='flex-1'>
          {children}
        </main>
      </div>
      <BottomMenu userData={userData}/>
    </section>
  );
};

export default DashboardLayout;
