import React from 'react';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

const UserCreatedDate = ({ user }) => {
    // Convert Firestore Timestamp to JavaScript Date
    const date = user.createdAt instanceof Timestamp ? user.createdAt.toDate() : new Date(user.createdAt);

    // Handle invalid date values
    if (isNaN(date.getTime())) {
      return <div>Invalid Date</div>;
    }
  
    // Format the date to show only the date part
    const formattedDate = format(date, 'MMMM d, yyyy');
  
    return <div>{formattedDate}</div>;
  };

export default UserCreatedDate;
