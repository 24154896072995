import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { collection, query, where, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import Content from '../components/Content';
import AdminScanner from '../components/AdminScanner';
import UsersComponent from '../components/UsersComponent';
import MatchDashboard from '../components/MatchDashboard';
import DashboardLayout from '../components/DashboardLayout';
import { Routes, Route } from 'react-router-dom';

const defaultUserData = {
  firstname: 'Guest',
  email: 'guest@email.com',
  photo: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80',
};

export const DashboardV2 = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAdmin, setTotalAdmin] = useState(0);
  const [totalVendor, setTotalVendor] = useState(0);
  const [totalScan, setTotalScan] = useState(0);
  const [morningCount, setMorningCount] = useState(0);
  const [afternoonCount, setAfternoonCount] = useState(0);
  const [eveningCount, setEveningCount] = useState(0);
  const [todayScanCount, setTodayScanCount] = useState(0);
  const [userData, setUserData] = useState(defaultUserData);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        setUserData(JSON.parse(storedUserData));
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, []);

  const getTodayDateString = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: 'YYYY-MM-DD'
  };

  const getStartOfToday = () => new Date(new Date().setHours(0, 0, 0, 0));
  const getEndOfToday = () => new Date(new Date().setHours(23, 59, 59, 999));

  useEffect(() => {
    const todayDateString = getTodayDateString();

    // Listener for daily scans count
    const scannedCollection = collection(db, 'scanned');
    const todayQuery = query(
      scannedCollection,
      where('lastScannedTime', '>=', Timestamp.fromDate(getStartOfToday())),
      where('lastScannedTime', '<', Timestamp.fromDate(getEndOfToday()))
    );
    const unsubscribeTodayScans = onSnapshot(todayQuery, (snapshot) => {
      setTodayScanCount(snapshot.size);
    });

    // Listener for morning, afternoon, and evening counts
    const subscribeToTimeCollections = (collectionName, setter) => {
      const collectionRef = collection(db, collectionName);
      const timeQuery = query(collectionRef, where('date', '==', todayDateString));
      return onSnapshot(timeQuery, (snapshot) => setter(snapshot.size));
    };

    const unsubscribeMorning = subscribeToTimeCollections('morning', setMorningCount);
    const unsubscribeAfternoon = subscribeToTimeCollections('afternoon', setAfternoonCount);
    const unsubscribeEvening = subscribeToTimeCollections('evening', setEveningCount);

    // Listener for total users excluding admins
    const usersCollection = collection(db, 'users');
    const usersQuery = query(usersCollection, where('isAdmin', '==', false));
    const unsubscribeUsers = onSnapshot(usersQuery, (snapshot) => {
      setTotalUsers(snapshot.size);
    });

    // Listener for total admins
    const adminQuery = query(collection(db, 'admin'));
    const unsubscribeAdmins = onSnapshot(adminQuery, (snapshot) => {
      setTotalAdmin(snapshot.size);
    });

    // Listener for total vendors
    const vendorQuery = query(usersCollection, where('role', 'in', ['vendor', 'juice vendor']));
    const unsubscribeVendors = onSnapshot(vendorQuery, (snapshot) => {
      setTotalVendor(snapshot.size);
    });

    // Listener for total scanned counts
    const unsubscribeTotalScans = onSnapshot(scannedCollection, (snapshot) => {
      setTotalScan(snapshot.size);
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeTodayScans();
      unsubscribeMorning();
      unsubscribeAfternoon();
      unsubscribeEvening();
      unsubscribeUsers();
      unsubscribeAdmins();
      unsubscribeVendors();
      unsubscribeTotalScans();
    };
  }, []); 

  return (
    <DashboardLayout userData={userData}>
      <Routes>
        {/* Define default route here */}
        <Route path="/dashboard" element={
          <Content
            totalUsers={totalUsers}
            totalVendors={totalVendor}
            totalScans={totalScan}
            morning={morningCount}
            afternoon={afternoonCount}
            evening={eveningCount}
            todayScan={todayScanCount}
          />
        } />
        <Route path="/admin-scanner" element={<AdminScanner />} />
        <Route path="/users" element={<UsersComponent />} />
        <Route path="/match-dashboard" element={<MatchDashboard />} />
        {/* Default route for /dashboard */}
        <Route index element={
          <Content
            totalUsers={totalUsers}
            totalVendors={totalVendor}
            totalScans={totalScan}
            morning={morningCount}
            afternoon={afternoonCount}
            evening={eveningCount}
            todayScan={todayScanCount}
          />
        } />
      </Routes>
    </DashboardLayout>
  );
};

export default DashboardV2;
