import React from 'react'
import { useState, useEffect } from 'react';
import { Sidebar } from '../components/Sidebar/Sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db } from '../firebase'; // Adjust the path to your Firebase config
import { collection, query, where, getDocs } from 'firebase/firestore';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';

export const Reports = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [scanData, setScanData] = useState({
    SuperAdmin: { morning: 0, afternoon: 0, evening: 0, total: 0 },
    Admin: { morning: 0, afternoon: 0, evening: 0, total: 0 },
    User: { morning: 0, afternoon: 0, evening: 0, total: 0 }
  });

  useEffect(() => {
    const fetchData = async () => {
      const morningStart = new Date(startDate);
      morningStart.setHours(0, 0, 0, 0);
      const morningEnd = new Date(startDate);
      morningEnd.setHours(11, 59, 59, 999);

      const afternoonStart = new Date(startDate);
      afternoonStart.setHours(12, 0, 0, 0);
      const afternoonEnd = new Date(startDate);
      afternoonEnd.setHours(17, 59, 59, 999);

      const eveningStart = new Date(startDate);
      eveningStart.setHours(18, 0, 0, 0);
      const eveningEnd = new Date(startDate);
      eveningEnd.setHours(23, 59, 59, 999);

      const scanRef = collection(db, 'scans');
      const q = query(
        scanRef,
        where('timestamp', '>=', morningStart),
        where('timestamp', '<=', eveningEnd)
      );

      const querySnapshot = await getDocs(q);
      const roleCounts = {
        SuperAdmin: { morning: 0, afternoon: 0, evening: 0, total: 0 },
        Admin: { morning: 0, afternoon: 0, evening: 0, total: 0 },
        User: { morning: 0, afternoon: 0, evening: 0, total: 0 }
      };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const role = data.role;
        const timestamp = data.timestamp.toDate();
        let timeOfDay;

        if (timestamp >= morningStart && timestamp <= morningEnd) {
          timeOfDay = 'morning';
        } else if (timestamp >= afternoonStart && timestamp <= afternoonEnd) {
          timeOfDay = 'afternoon';
        } else if (timestamp >= eveningStart && timestamp <= eveningEnd) {
          timeOfDay = 'evening';
        }

        if (roleCounts[role] && timeOfDay) {
          roleCounts[role][timeOfDay]++;
          roleCounts[role].total++;
        }
      });

      setScanData(roleCounts);
    };

    fetchData();
  }, [startDate]);

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { width, height } = page.getSize();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const margin = 50;
    const tableWidth = 500;
    const tableTop = height - margin - 100; // Adjust table position
    const rowHeight = 20;
    const columnWidth = [120, 100, 100, 100, 100];

    // Draw header
    page.drawText('Scan Data Report', {
      x: margin,
      y: height - margin,
      size: 16,
      font: font,
      color: rgb(0, 0, 0),
    });

    // Draw date
    const formattedDate = `Date: ${startDate.toDateString()}`;
    page.drawText(formattedDate, {
      x: width - margin - font.widthOfTextAtSize(formattedDate, fontSize),
      y: height - margin,
      size: fontSize,
      font: font,
      color: rgb(0, 0, 0),
    });

    // Draw table
    const headers = ['Role', 'Morning', 'Afternoon', 'Evening', 'Total'];
    const yStart = tableTop;
    headers.forEach((header, i) => {
      page.drawText(header, {
        x: margin + columnWidth.slice(0, i).reduce((a, b) => a + b, 0),
        y: yStart,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
    });

    // Draw table rows
    let yPosition = yStart - rowHeight;
    Object.keys(scanData).forEach(role => {
      const data = scanData[role];
      page.drawText(role, { x: margin, y: yPosition, size: fontSize, font: font });
      page.drawText(data.morning.toString(), { x: margin + columnWidth[0], y: yPosition, size: fontSize, font: font });
      page.drawText(data.afternoon.toString(), { x: margin + columnWidth[0] + columnWidth[1], y: yPosition, size: fontSize, font: font });
      page.drawText(data.evening.toString(), { x: margin + columnWidth[0] + columnWidth[1] + columnWidth[2], y: yPosition, size: fontSize, font: font });
      page.drawText(data.total.toString(), { x: margin + columnWidth[0] + columnWidth[1] + columnWidth[2] + columnWidth[3], y: yPosition, size: fontSize, font: font });
      yPosition -= rowHeight;
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'scan_data_report.pdf');
  };

  return (
    <div className="pt-10 pl-0 w-full flex flex-col items-center justify-center h-screen bg-gray-100 overflow-x-scroll">
      <Sidebar />
      <div className="w-full max-w-4xl">
        <div className="flex justify-between items-center"> 
          <div className="text-xl font-bold ">Date:
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
              className="bg-transparent border-2 w-40"
            />
          </div>
          <button
            onClick={downloadPDF}
            className="w-28 tracking-wide font-semibold bg-indigo-500 text-gray-100 py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out focus:shadow-outline focus:outline-none"
          >
            Download
          </button>

        </div>
        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto border-gray-300 rounded-lg shadow-md mt-4">
          <thead className="bg-gray-100">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Morning</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Afternoon</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Evening</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            {Object.keys(scanData).map((role) => (
              <tr key={role}>
                <td className="text-sm px-6 py-4 text-gray-900 font-semibold">{role}</td>
                <td className="text-sm px-6 py-4 text-gray-900">{scanData[role].morning}</td>
                <td className="text-sm px-6 py-4 text-gray-900">{scanData[role].afternoon}</td>
                <td className="text-sm px-6 py-4 text-gray-900">{scanData[role].evening}</td>
                <td className="text-sm px-6 py-4 text-gray-900">{scanData[role].total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
