import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import '../App.css';
import bcrypt from 'bcryptjs';
import Modal from '../Model/NotRegister.jsx';
import logo from '../assets/WZlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import '../assets/css/login.css';

export const LoginAdmin = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [redirectToRegister, setRedirectToRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'userId') setUserId(value);
    if (id === 'password') setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const collections = ['users'];
      for (const collection of collections) {
        console.log(`Checking ${collection} collection...`);
        const docRef = doc(db, collection, userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          console.log(`${collection} data:`, userData);
          const hashedPassword = await bcrypt.hash(password, 10);
          if (hashedPassword) {
            Cookies.set('user', userId);
            localStorage.setItem('userData', JSON.stringify(userData));
            if (userData.role === 'admin' || userData.role === 'tournament organiser') {
              navigate('/dashboard');
            } else if (userData.role === 'vendor') {
              navigate('/vendor');
            } else if ( userData.role === 'juice vendor') {
              navigate('/juice-vendor');
            }
            return;
          } else {
            setModalMessage(`Incorrect password for ${collection}`);
            setShowModal(true);
            return;
          }
        }
      }
      setModalMessage('User not found. Please register.');
      setShowModal(true);
      setRedirectToRegister(true);
    } catch (error) {
      console.error('Login error', error);
      setModalMessage('Error logging in. Please check your credentials and try again.');
      setShowModal(true);
    } finally {
      setLoading(false);
      setPassword('');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage('');
    setRedirectToRegister(false);
  };

  const togglePasswordVisibility = () => {
    if (password) {
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="h-[100svh] bg-black text-gray-900 flex justify-center items-center mx-auto w-screen overflow-hidden">
      <div className="relative w-full h-full sm:rounded-lg flex justify-end items-center">
        <div className='responsive__login bg-white z-[10] h-[100svh] relative flex justify-center items-center'>
        <div className='absolute top-[.8em] right-[.8em]'>
        <a href="/"
                  className="tracking-wide font-medium text-[.8em] uppercase text-indigo-700 w-fit px-[.8em] py-[.3em] transition-all duration-300 ease-in-out flex items-center justify-center hover:border-b-[1px] hover:border-indigo-700"
                >
                  Login as user
                </a>
        </div>
        <div className=" p-6 sm:p-12" style={{ backgroundColor : 'rgba(255, 255, 255, 0.1)' }}>
          <div>
            <img src={logo} className="w-40 mx-auto" alt="Logo" />
          </div>
          <form className="login__form mt-12 flex flex-col items-center" onSubmit={handleSubmit}>
            <div className="min-w-[20em] w-full flex-1 mt-8">
              <div className="mx-auto max-w-xs">
                <input
                  className="w-full px-[.6em] py-[.8em] text-black font-medium border-b-[1px] border-[#88b0d9] placeholder-[#88b0d9] text-sm outline-none focus:border-black transition-all duration-[250ms] ease-in "
                  style={{ borderTop: '0', borderRight: '0', borderBottom: '1px solid #88b0d9', borderLeft: '0' }}
                  type="text"
                  name='user'
                  id="userId"
                  placeholder="Mobile No."
                  value={userId}
                  onChange={handleChange}
                  required
                />
                <div className="relative mt-5">
                  <input
                    className="w-full px-[.6em] py-[.8em] text-blac font-medium border-b-[1px] border-[#88b0d9] placeholder-[#88b0d9] text-sm outline-none focus:border-black transition-all duration-[250ms] ease-in"
                    style={{ borderTop: '0', borderRight: '0', borderBottom: '1px solid #88b0d9', borderLeft: '0' }}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleChange}
                    required
                  />
                  {password && (
                    <span
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400 text-sm"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                  )}
                </div>
                <button
                  className="mt-5 tracking-wide font-semibold bg-indigo-700 text-[.8em] uppercase text-white w-fit py-[1em] px-[5em] rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                ) : (
                  <i className="fas fa-user-plus fa 1x w-6 -ml-6" />
                )}
                <span className="ml-1 text-[14px] uppercase">
                  {loading ? 'Loading...' : 'Login'}
                </span>
                </button>
                  <div className='w-full flex flex-col gap-[.2em] items-center justify-center mt-[2em]'>
                 {/*  <label className='password__forgot-label text-[0.8em] text-red-600'> <span className=''><a href=''>Forgot Password</a></span></label> */}
                
                  </div>
                {/* <p className="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by templatana's
                  <a href="#" className="border-b border-gray-500 border-dotted">
                    Terms of Service
                  </a>
                  and its
                  <a href="#" className="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </a>
                </p> */}
              </div>
            </div>
          </form>
        </div>
        </div>
        <div className="absolute top-0 left-0 h-full w-full bg-black text-center hidden lg:flex">
          <div
            className=" w-full bg-[80em] bg-no-repeat"
            style={{
              backgroundImage: 'url(https://ik.imagekit.io/gunadev/Badminton/collection-shuttercocks.jpg?updatedAt=1723295965665)', backgroundPosition : '-10em -10em', opacity : .9,
            }}
          />
        </div>
        <Modal show={showModal} message={modalMessage} onClose={closeModal} />
      </div>
    </div>
  );
};