import React from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import useQrScanner from '../hook/useQrScanner';
import { ToastContainer } from 'react-toastify';

const AdminScannerComponent = () => {
  const {
    error,
    isScanning,
    cameraAvailable,
    isVerifying,
    isModalOpen,
    userDetails,
    closeModal,
    handleScan,
    handleError,
    handleScanNowClick
  } = useQrScanner();

  const previewStyle = {
    height: 250,
    width: 250,
  };

  const constraints = {
    video: { facingMode: { exact: 'environment' } },
  };

  return (
    <div className="w-full lg:ps-64 h-[100svh] overflow-hidden">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
    <div className={`pt-0 pl-0 w-full ${isScanning ? 'blur-background' : ''}`}>
      <div className="flex items-center justify-center h-screen p-4 w-full">
        <div className="flex flex-col items-center relative">
          <h1 className="text-2xl mb-4">QR Code Scanner</h1>
          <div className="w-full max-w-md flex flex-col items-center">
            {!isScanning ? (
              <button 
                onClick={handleScanNowClick}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                Scan Now
              </button>
            ) : (
              <Scanner onScan={handleScan} onError={handleError} previewStyle={previewStyle} constraints={constraints} />
            )}
          </div>
          {isVerifying && (
            <div className="popup-modal absolute inset-0 flex items-center justify-center z-50">
              <div className="modal-content p-4 border border-gray-300 rounded-md shadow-lg w-full max-w-md bg-white relative">
                <h2 className="text-xl mb-4">Verifying User...</h2>
                <p className="text-gray-600">Please wait while we verify the user.</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/40 flex items-center justify-center z-[9999]">
        <div className="bg-white rounded-lg p-6 w-80 max-w-full shadow-lg text-center relative">
          {userDetails ? (
            <>
              <h2 className="text-xl font-semibold mb-4 text-green-500">Verified User</h2>
              <img 
                src={userDetails.photo || 'default-avatar.jpg'} 
                alt="User" 
                className="w-24 h-24 rounded-full mx-auto mb-4 object-cover" 
              />
              <p className="text-sm text-gray-600 mb-2">Name: {userDetails.firstname}</p>
              <p className="text-sm text-gray-600 mb-2">Role: {userDetails.role}</p>
              <p className="text-sm text-gray-600 mb-6">State: {userDetails.state}</p>
            </>
          ) : (
            <p className="text-red-500 mb-6">{error || 'No user data available'}</p>
          )}
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
      )}
      <ToastContainer />
    </div>
    </div>
    </div>
  );
};

export default AdminScannerComponent;
