import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import '../App.css';
import bcrypt from 'bcryptjs';
import Modal from '../Model/NotRegister.jsx';
import RegisterInfo from '../Model/RegisterInfo.jsx';
import logo from '../assets/WZlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import '../assets/css/login.css';

export const Login = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showRegisterInfo, setRegisterInfo] = useState(false);
  const [redirectToRegister, setRedirectToRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'userId') setUserId(value);
    if (id === 'password') setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const collections = ['users'];
      for (const collection of collections) {
        console.log(`Checking ${collection} collection...`);
        const docRef = doc(db, collection, userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          console.log(`${collection} data:`, userData);
          const passwordMatch = await bcrypt.compare(password, userData.password);
          if (passwordMatch) {
            Cookies.set('user', userId); 
            localStorage.setItem('userData', JSON.stringify(userData));
            if (userData.role != 'admin' || userData.role != 'vendor' || userData.role != 'juice vendor'){
              if (userData.status === 'inactive') {
                setModalMessage('Your account is inactive. Please contact the admin.');
                setShowModal(true);
                return;
              }
              navigate('/event-ticket', { state: { userData } });
            }
            return;
          } else {
            setModalMessage(`Incorrect password for ${collection}`);
            setShowModal(true);
            return;
          }
        }
      }
      setModalMessage('User not found. Please register.');
      setShowModal(true);
      setRedirectToRegister(true);
    } catch (error) {
      console.error('Login error', error);
      setModalMessage('Error logging in. Please check your credentials and try again.');
      setShowModal(true);
    } finally {
      setLoading(false);
      setPassword('');
    }
  };

  const handleRegisterInfo = () => {
    setRegisterInfo(true);
  };

  const closeRegisterInfo = () => {
    setRegisterInfo(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage('');
    setRedirectToRegister(false);
  };

  const togglePasswordVisibility = () => {
    if (password) {
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="h-[100svh] bg-black text-gray-900 flex justify-center items-center mx-auto w-screen overflow-hidden">
      <div className="relative w-full h-full sm:rounded-lg flex justify-end items-center">
        <div className='responsive__login md:bg-white z-[10] h-[100svh] relative flex justify-center items-end md:items-center'>
          <div className='absolute top-[.8em] right-[.8em]'>
            <a href="/login-admin-vendor" className="tracking-wide font-medium text-[.8em] uppercase text-indigo-700 w-fit px-[.8em] py-[.3em] transition-all duration-300 ease-in-out flex items-center justify-center hover:border-b-[1px] hover:border-indigo-700">
              Login as Admin/Vendor
            </a>
          </div>
          <div className=" p-6 sm:p-12 login__wrapper">
            <div>
              <img src={logo} className="w-40 mx-auto" alt="Logo" />
            </div>
            <form className="login__form mt-12 flex flex-col items-center" onSubmit={handleSubmit}>
              <div className="min-w-[20em] w-full flex-1 mt-8">
                <div className="mx-auto max-w-xs">
                  <input
                    className="w-full px-[.6em] py-[.8em] text-black font-medium placeholder-[#88b0d9] text-sm outline-none focus:border-black transition-all duration-[250ms] ease-in "
                    style={{ borderTop: '0', borderRight: '0', borderBottom: '1px solid #88b0d9', borderLeft: '0' }}
                    type="text"
                    name='user'
                    id="userId"
                    placeholder="Mobile No."
                    value={userId}
                    onChange={handleChange}
                    required
                  />
                  <div className="relative mt-5">
                    <input
                      className="w-full px-[.6em] py-[.8em] text-black font-medium placeholder-[#88b0d9] text-sm outline-none focus:border-black transition-all duration-[250ms] ease-in"
                      style={{ borderTop: '0', borderRight: '0', borderBottom: '1px solid #88b0d9', borderLeft: '0' }}
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={handleChange}
                      required
                    />
                    {password && (
                      <span
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400 text-sm"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </span>
                    )}
                  </div>
                  <button
                    className="mt-5 tracking-wide font-semibold bg-indigo-700 text-[.8em] uppercase text-white w-fit py-[1em] px-[5em] rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                    disabled={loading} // Disable button when loading
                  >
                     {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                ) : (
                  <i className="fas fa-user-plus fa 1x w-6 -ml-6" />
                )}
                <span className="ml-1 text-[14px] uppercase">
                  {loading ? 'Loading...' : 'Login'}
                </span>
                  </button>
                  <div className='w-full flex flex-col gap-[.2em] items-center justify-center mt-[2em]'>
                   {/* <label className='password__forgot-label text-[0.8em] text-red-600'>
                      <a href=''>Forgot Password</a>
                    </label> */}
                    <a href="/matches" target='_blank' className='text-[0.8em]'>
                      <span className='register__label text-indigo-600 hover:text-indigo-800 cursor-pointer'>
                        Live Matches
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="absolute top-0 left-0 h-full w-full bg-black text-center flex">
          <div
            className=" w-full login__bg-position bg-cover bg-no-repeat"
            style={{
              backgroundImage: 'url(https://ik.imagekit.io/gunadev/Badminton/view-tennis-player-with-digital-art-style-effect_23-2151737614.jpg_t=st=1723528113~exp=1723531713~hmac=88dc9b42d4f66dba11fa727bc620a3937912c5fe6c95b33043f2062d4fa0f003&w=1480?updatedAt=1723528148408)', opacity : .9,
            }}
          />
        </div>
        <Modal show={showModal} message={modalMessage} onClose={closeModal} />
        <RegisterInfo show={showRegisterInfo} onClose={closeRegisterInfo} />
      </div>
    </div>
  );
};