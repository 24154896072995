import React, { useEffect } from 'react';

const ThemeSwitcher = () => {
  useEffect(() => {
    const html = document.querySelector('html');
    const isLightOrAuto =
      localStorage.getItem('hs_theme') === 'light' ||
      (localStorage.getItem('hs_theme') === 'auto' &&
        !window.matchMedia('(prefers-color-scheme: dark)').matches);
    const isDarkOrAuto =
      localStorage.getItem('hs_theme') === 'dark' ||
      (localStorage.getItem('hs_theme') === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);

    if (isLightOrAuto && html.classList.contains('dark')) {
      html.classList.remove('dark');
    } else if (isDarkOrAuto && html.classList.contains('light')) {
      html.classList.remove('light');
    } else if (isDarkOrAuto && !html.classList.contains('dark')) {
      html.classList.add('dark');
    } else if (isLightOrAuto && !html.classList.contains('light')) {
      html.classList.add('light');
    }
  }, []); // The empty dependency array ensures this runs only once after the initial render

  return null; // This component doesn't render anything itself
};

export default ThemeSwitcher;
