import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

const MatchDashboard = () => {
  const [matches, setMatches] = useState({
    live: [],
    scheduled: [],
    completed: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [editingMatch, setEditingMatch] = useState(null);
  const [matchDetails, setMatchDetails] = useState({
    player1: "",
    player2: "",
    player1Mobile: "",
    player2Mobile: "",
    sets: 3,
    scores: Array(3).fill({ player1Score: "", player2Score: "" }),
    isLive: false,
    isComplete: false,
    scheduleTime: "", // To handle scheduled matches
    serve: "player1", //
    description: "",
    courtNumber: "",
    createdDate: new Date().toISOString().split("T")[0],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    fetchMatches(e.target.value); // Fetch matches based on the selected date
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async (
    date = new Date().toISOString().split("T")[0]
  ) => {
    try {
      setLoading(true);
      const matchesCollection = collection(db, "matches");
      const q = query(matchesCollection, where("createdDate", "==", date));
      const matchesSnapshot = await getDocs(q);
      const allMatches = matchesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      categorizeMatches(allMatches);
    } catch (error) {
      console.error("Error fetching matches:", error);
      setError("Failed to fetch matches.");
    } finally {
      setLoading(false);
    }
  };

  const categorizeMatches = (fetchedMatches) => {
    const live = [];
    const scheduled = [];
    const completed = [];

    fetchedMatches.forEach((match) => {
      if (match.isComplete) {
        completed.push(match);
      } else if (match.isLive) {
        live.push(match);
      } else {
        scheduled.push(match);
      }
    });

    setMatches({ live, scheduled, completed });
  };

  const openModal = (match = null) => {
    setEditingMatch(match);
    setMatchDetails({
      player1: match ? match.player1 : "",
      player2: match ? match.player2 : "",
      sets: match ? match.sets : 3,
      scores: match
        ? match.scores
        : Array(3).fill({ player1Score: "", player2Score: "" }),
      isLive: match ? match.isLive : false,
      isComplete: false,
      scheduleTime: match ? match.scheduleTime : "", // For scheduled matches
      serve: match ? match.serve : "player1", // Make sure this is included
      description: match ? match.description : "", // Make sure this is included
      courtNumber: match ? match.courtNumber : "", // Make sure this is included
    });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setError(""); // Clear error when closing modal
  };

  const handleMatchChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === "sets" ? parseInt(value, 10) : value;

    if (name === "sets") {
      const newSets = parsedValue > 0 ? parsedValue : 1; // Set a default minimum value if 0 or less
      setMatchDetails((prev) => {
        const newScores = Array(newSets).fill({
          player1Score: "",
          player2Score: "",
        });

        // Preserve existing scores if the number of sets is increased
        for (let i = 0; i < Math.min(newSets, prev.scores.length); i++) {
          newScores[i] = prev.scores[i];
        }

        return { ...prev, sets: newSets, scores: newScores };
      });
    } else {
      setMatchDetails((prev) => ({ ...prev, [name]: parsedValue }));
    }
  };

  const handleScoreChange = (index, player, value) => {
    const newScores = [...matchDetails.scores];
    newScores[index] = { ...newScores[index], [`${player}Score`]: value };
    setMatchDetails((prev) => ({ ...prev, scores: newScores }));
  };

  const handleSaveMatch = async () => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString().split("T")[0];
      const newMatchDetails = {
        ...matchDetails,
        createdDate: matchDetails.isLive
          ? currentDate
          : matchDetails.scheduleTime.split("T")[0],
        // isComplete: checkIfMatchComplete(matchDetails),
      };

      if (editingMatch) {
        const matchRef = doc(db, "matches", editingMatch.id);
        await updateDoc(matchRef, newMatchDetails);
      } else {
        await addDoc(collection(db, "matches"), newMatchDetails);
      }
      fetchMatches();
      closeModal();
    } catch (error) {
      console.error("Error adding/updating match:", error);
      setError("Failed to save match.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMatch = async (id) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "matches", id));
      fetchMatches();
    } catch (error) {
      console.error("Error deleting match:", error);
      setError("Failed to delete match.");
    } finally {
      setLoading(false);
    }
  };

  const handleLiveMatch = async (id) => {
    const currentDate = new Date().toISOString().split("T")[0];
    try {
      setLoading(true);
      await updateDoc(doc(db, "matches", id), {
        isLive: true,
        isComplete: false,
        scheduleTime: currentDate,
        createdDate: currentDate,
      });
      fetchMatches();
    } catch (error) {
      console.error("Error updating match status:", error);
      setError("Failed to update match status.");
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteMatch = async (id) => {
    try {
      setLoading(true);
      await updateDoc(doc(db, "matches", id), {
        isLive: false,
        isComplete: true,
      });
      fetchMatches();
    } catch (error) {
      console.error("Error updating match status:", error);
      setError("Failed to update match status.");
    } finally {
      setLoading(false);
    }
  };

  const checkIfMatchComplete = (matchDetails) => {
    console.log("triggered");
    return matchDetails.scores.every(
      (score) => score.player1Score !== "" && score.player2Score !== ""
    );
  };

  const calculateTotalScores = (scores) => {
    let player1Total = 0;
    let player2Total = 0;

    scores.forEach((score) => {
      player1Total += parseInt(score.player1Score || "0", 10);
      player2Total += parseInt(score.player2Score || "0", 10);
    });

    return { player1Total, player2Total };
  };

  const getMatchStatus = (player1Total, player2Total) => {
    if (player1Total > player2Total) {
      return { player1: "W", player2: "L" };
    } else if (player1Total < player2Total) {
      return { player1: "L", player2: "W" };
    } else {
      return { player1: "D", player2: "D" }; // Handle draws if necessary
    }
  };

  const formatScore = (score) => (score === "" ? "--" : score);

  const colors = ["bg-orange-500", "bg-white"];

  return (
    <div className="w-full lg:ps-64">
      <div className="p-6">
        <div className="w-full flex justify-between items-center border-b-[1px] border-gray-100 pb-2">
          <div className="date-filter">
            <label className="text-gray-500">Select Date: </label>
            <input
              className="border border-gray-200 bg-transparent rounded-md"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <button
            onClick={() => openModal()}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          >
            <svg
              className="shrink-0 size-4"
              enableBackground="new 0 0 512 512"
              height="512px"
              viewBox="0 0 512 512"
              width="512px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g>
                <path
                  d="M256.108,3.02c-139.743,0-253,113.257-253,253s113.257,252.995,253,252.995
           c139.743,0,253-113.252,253-252.995S395.852,3.02,256.108,3.02z M256.108,488.775c-128.338,0-232.76-104.417-232.76-232.755
           c0-128.339,104.422-232.76,232.76-232.76c128.338,0,232.76,104.421,232.76,232.76C488.868,384.358,384.446,488.775,256.108,488.775
           z"
                  fill="currentColor"
                />
                <polygon
                  fill="currentColor"
                  points="266.228,104.22 245.988,104.22 245.988,245.9 104.98,245.9 104.98,266.14 245.988,266.14
                245.988,407.148 266.228,407.148 266.228,266.14 407.908,266.14 407.908,245.9 266.228,245.9"
                />
              </g>
            </svg>
            Add Match
          </button>
        </div>

        {loading && (
          <div className="mt-4 text-center text-blue-500">Loading...</div>
        )}
        {error && <div className="mt-4 text-center text-red-500">{error}</div>}

        <div className="mt-6 flex flex-col justify-center border-b-[1px] border-gray-100 pb-4">
          <h2 className="text-xl font-normal mb-4 bg-indigo-100 py-4 px-2">
            Live Matches
          </h2>
          {matches.live.length === 0 ? (
            <div className="text-center text-gray-500">No matches here</div>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {matches.live.map((match) => {
                const randomColor =
                  colors[Math.floor(Math.random() * colors.length)];
                return (
                  <div
                    key={match.id}
                    className="relative flex flex-col bg-white bg-opacity-10 p-4 rounded shadow"
                  >
                    <div className="absolute top-2 right-2 text-red-500 px-2 py-1 rounded text-xs font-semibold">
                      <span className="animate-ping">·</span> Live
                    </div>
                    <h3 className="text-lg font-normal">
                      {match.player1}{" "}
                      <span className="">
                        vs
                      </span>{" "}
                      {match.player2}
                    </h3>
                    <table className="mt-2 w-full border-collapse flex-grow">
                      <thead className="bg-white">
                        <tr>
                          <th className="border border-indigo-100 p-2 font-light text-[14px] lg:text-[16px]">
                            Set
                          </th>
                          <th className="border border-indigo-100 p-2 font-light text-[14px] lg:text-[16px]">
                            {match.player1}
                          </th>
                          <th className="border border-indigo-100 p-2 font-light text-[14px] lg:text-[16px]">
                            {match.player2}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {match.scores.map((score, index) => (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "bg-indigo-100" : "bg-white"
                            }
                          >
                            <td className="border border-indigo-100 p-2 text-center font-light text-[12px] lg:text-[14px]">
                              Set {index + 1}
                            </td>
                            <td className="border border-indigo-100 p-2 text-center font-light text-[12px] lg:text-[14px]">
                              {formatScore(score.player1Score)}
                            </td>
                            <td className="border border-indigo-100 p-2 text-center font-light text-[12px] lg:text-[14px]">
                              {formatScore(score.player2Score)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-4 flex w-full justify-center space-x-2">
                      <button
                        onClick={() => openModal(match)}
                        className="flex items-center border-r-[1px] border-gray-200 gap-1 text-yellow-500 font-light text-[14px] lg:text-[16px] px-2 py-1"
                      >
                        <svg
                          className="shrink-0 size-4"
                          style={{ enableBackground: "new 0 0 24 24" }}
                          version="1.1"
                          viewBox="0 0 24 24"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <style type="text/css">
                            {`
        .st0 {
          opacity: 0.2;
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.01;
          stroke-miterlimit: 10;
        }
      `}
                          </style>
                          <g id="grid_system" />
                          <g id="_icons">
                            <g>
                              <path
                                d="M21.1,2.9c-1.1-1.1-3-1.1-4.2,0l-8.1,7.9c-0.7,0.7-1.2,1.6-1.4,2.6L7,15.6c-0.1,0.3,0,0.7,0.3,0.9
          c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.1,0,0.2,0l2.2-0.4c1-0.2,1.9-0.7,2.6-1.4L21.1,7C21.7,6.5,22,5.7,22,4.9
          C22,4.1,21.7,3.4,21.1,2.9z M11.7,13.5c-0.4,0.4-1,0.7-1.6,0.8l-0.8,0.2l0.2-0.7c0.1-0.6,0.4-1.1,0.9-1.6l6.2-6.1l1.4,1.3
          L11.7,13.5z M19.7,5.6L19.3,6l-1.4-1.3l0.4-0.4C18.5,4.1,18.8,4,19,4c0.3,0,0.5,0.1,0.7,0.3C19.9,4.5,20,4.7,20,4.9
          C20,5.1,20,5.4,19.7,5.6z"
                                fill="currentColor"
                              />
                              <path
                                d="M20,9.9c-0.6,0-1,0.4-1,1V16c0,1.7-1.3,3-3,3H8c-1.7,0-3-1.3-3-3V8c0-1.7,1.3-3,3-3h5c0.6,0,1-0.4,1-1s-0.4-1-1-1H8
          C5.2,3,3,5.2,3,8v8c0,2.8,2.2,5,5,5h8c2.8,0,5-2.2,5-5v-5.1C21,10.4,20.6,9.9,20,9.9z"
                                fill="currentColor"
                              />
                            </g>
                          </g>
                        </svg>
                        Edit
                      </button>
                      <button
                        onClick={() => handleCompleteMatch(match.id)}
                        className="flex items-center border-r-[1px] border-gray-200 gap-1 text-green-500 font-light text-[14px] lg:text-[16px] px-4 py-2"
                      >
                        <svg
                          className="shrink-0 size-4"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          version="1.1"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <style type="text/css">
                            {`
        .st0 {
          fill: #232323;
        }
      `}
                          </style>
                          <g>
                            <path
                              d="M256,0.2C114.9,0.2,0.1,115,0.1,256.1S114.9,512,256,512c64.5,0,126.1-24.1,173.5-67.8
        c4.5-4.2,4.8-11.2,0.6-15.7c-4.2-4.5-11.2-4.8-15.7-0.6c-43.3,39.9-99.5,61.9-158.4,61.9C127.1,489.8,22.3,385,22.3,256.1
        S127.1,22.3,256,22.3s233.7,104.9,233.7,233.7c0,43.7-12.1,86.3-35,123.2c-3.2,5.2-1.6,12,3.6,15.3c5.2,3.2,12,1.6,15.3-3.6
        c25.1-40.4,38.4-87.1,38.4-134.9C511.9,115,397.1,0.2,256,0.2z"
                              fill="currentColor"
                            />
                            <path
                              d="M363.5,180.1L223.2,327.7l-75.8-60.2c-4.8-3.8-11.8-3-15.6,1.8c-3.8,4.8-3,11.8,1.8,15.6l83.8,66.5
        c2,1.6,4.5,2.4,6.9,2.4c2.9,0,5.9-1.2,8-3.5l147.3-155c4.2-4.4,4-11.5-0.4-15.7C374.7,175.5,367.7,175.7,363.5,180.1z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                        Mark Finished
                      </button>
                      <button
                        onClick={() => handleDeleteMatch(match.id)}
                        className="flex items-center gap-1 text-red-500 font-light text-[14px] lg:text-[16px] px-4 py-2 "
                      >
                        <svg
                          className="shrink-0 size-4"
                          enableBackground="new 0 0 50 50"
                          height="50px"
                          id="Layer_1"
                          version="1.1"
                          viewBox="0 0 50 50"
                          width="50px"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <rect fill="none" height="50" width="50" />
                          <path
                            d="M19,6V3c0-1.104,0.896-2,2-2  h8c1.104,0,2,0.896,2,2v3"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                          />
                          <path
                            d="M40,6l-3.693,41.426  C36.229,48.299,35.469,49,34.608,49H15.391c-0.86,0-1.621-0.701-1.699-1.574L10,6"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                          />
                          <line
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                            x1="8"
                            x2="42"
                            y1="6"
                            y2="6"
                          />
                          <line
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                            x1="25"
                            x2="25"
                            y1="11"
                            y2="44"
                          />
                          <line
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                            x1="31"
                            x2="31"
                            y1="11"
                            y2="44"
                          />
                          <line
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                            x1="19"
                            x2="19"
                            y1="11"
                            y2="44"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="mt-6 flex flex-col justify-center border-b-[1px] border-gray-100 pb-4">
          <h2 className="text-xl font-normal mb-4 bg-indigo-100 py-4 px-2">
            Scheduled Matches
          </h2>
          {matches.scheduled.length === 0 ? (
            <div className="text-center text-gray-500">No matches here</div>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {matches.scheduled.map((match) => (
                <div key={match.id} className="bg-white p-4 rounded shadow">
                  <h3 className="text-lg font-normal">
                    {match.player1}{" "}
                    <span className="">
                      vs
                    </span>{" "}
                    {match.player2}
                  </h3>
                  <div className="text-gray-600">
                    Scheduled for:{" "}
                    {new Date(match.scheduleTime).toLocaleString()}
                  </div>
                  <div className="mt-4 flex space-x-2">
                    <button
                      onClick={() => handleLiveMatch(match.id)}
                      className="flex items-center border-r-[1px] border-gray-200 gap-1 text-green-500 font-light text-[14px] lg:text-[16px] pr-4 py-2"
                    >
                      <svg
                        className="shrink-0 size-4"
                        style={{ enableBackground: "new 0 0 50 50" }}
                        version="1.1"
                        viewBox="0 0 50 50"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g id="Layer_1">
                          <path
                            d="M7,1.217v47.566L45.918,25L7,1.217z M9,4.783L42.082,25L9,45.217V4.783z"
                            fill="currentColor"
                          />
                        </g>
                        <g />
                      </svg>
                      Start Match
                    </button>
                    <button
                      onClick={() => handleDeleteMatch(match.id)}
                      className="flex items-center gap-1 text-red-500 font-light text-[14px] lg:text-[16px] pr-4 py-2 "
                    >
                      <svg
                        className="shrink-0 size-4"
                        enableBackground="new 0 0 50 50"
                        height="50px"
                        id="Layer_1"
                        version="1.1"
                        viewBox="0 0 50 50"
                        width="50px"
                        xmlSpace="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <rect fill="none" height="50" width="50" />
                        <path
                          d="M19,6V3c0-1.104,0.896-2,2-2  h8c1.104,0,2,0.896,2,2v3"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                        />
                        <path
                          d="M40,6l-3.693,41.426  C36.229,48.299,35.469,49,34.608,49H15.391c-0.86,0-1.621-0.701-1.699-1.574L10,6"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          x1="8"
                          x2="42"
                          y1="6"
                          y2="6"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          x1="25"
                          x2="25"
                          y1="11"
                          y2="44"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          x1="31"
                          x2="31"
                          y1="11"
                          y2="44"
                        />
                        <line
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          x1="19"
                          x2="19"
                          y1="11"
                          y2="44"
                        />
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="mt-6 flex flex-col justify-center border-b-[1px] border-gray-100 pb-4">
          <h2 className="text-xl font-normal mb-4 bg-indigo-100 py-4 px-2">
            Completed Matches
          </h2>
          {matches.completed.length === 0 ? (
            <div className="text-center text-gray-500">No matches here</div>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {matches.completed.map((match) => {
                const { player1Total, player2Total } = calculateTotalScores(
                  match.scores
                );
                const status = getMatchStatus(player1Total, player2Total);

                return (
                  <div key={match.id} className="bg-white p-4 rounded shadow">
                    <div className="flex justify-between items-center">
                      <div className="h-fit">
                        <h3 className="text-lg font-normal">
                          {match.player1}{" "}
                          <span className="">
                            vs
                          </span>{" "}
                          {match.player2}
                        </h3>
                      </div>
                      <div className="flex">
                        <button
                          onClick={() => handleDeleteMatch(match.id)}
                          className="flex items-center gap-1 text-red-500 font-light text-[14px] lg:text-[16px] py-2 "
                        >
                          <svg
                            className="shrink-0 size-4"
                            enableBackground="new 0 0 50 50"
                            height="50px"
                            id="Layer_1"
                            version="1.1"
                            viewBox="0 0 50 50"
                            width="50px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <rect fill="none" height="50" width="50" />
                            <path
                              d="M19,6V3c0-1.104,0.896-2,2-2  h8c1.104,0,2,0.896,2,2v3"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                            />
                            <path
                              d="M40,6l-3.693,41.426  C36.229,48.299,35.469,49,34.608,49H15.391c-0.86,0-1.621-0.701-1.699-1.574L10,6"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                            />
                            <line
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              x1="8"
                              x2="42"
                              y1="6"
                              y2="6"
                            />
                            <line
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              x1="25"
                              x2="25"
                              y1="11"
                              y2="44"
                            />
                            <line
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              x1="31"
                              x2="31"
                              y1="11"
                              y2="44"
                            />
                            <line
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              x1="19"
                              x2="19"
                              y1="11"
                              y2="44"
                            />
                          </svg>
                          Delete
                        </button>
                      </div>
                    </div>
                    <table className="mt-2 w-full border-collapse hidden">
                      <thead>
                        <tr>
                          <th className="border p-2">Set</th>
                          <th className="border p-2">{match.player1}</th>
                          <th className="border p-2">{match.player2}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {match.scores.map((score, index) => (
                          <tr key={index}>
                            <td className="border p-2 text-center">
                              Set {index + 1}
                            </td>
                            <td className="border p-2 text-center">
                              {formatScore(score.player1Score)}
                            </td>
                            <td className="border p-2 text-center">
                              {formatScore(score.player2Score)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-4">
                      <div
                        className={`flex justify-between ${
                          status.player1 === "L"
                            ? "text-gray-400"
                            : "text-green-600"
                        }`}
                      >
                        <span>{match.player1}</span>
                        <span>{status.player1}</span>
                      </div>
                      <div
                        className={`flex justify-between ${
                          status.player2 === "L"
                            ? "text-gray-400"
                            : "text-green-600"
                        }`}
                      >
                        <span>{match.player2}</span>
                        <span>{status.player2}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <Dialog
          open={isOpen}
          onClose={closeModal}
          className="fixed inset-0 flex items-center justify-center bg-black/20 p-4  max-w-screen z-[999]"
        >
          <div
            className={`bg-white rounded-lg shadow-lg w-[90%]  xl:w-[50%] max-h-[90svh] overflow-y-scroll`}
          >
            <h3 className="text-lg font-normal mb-4 bg-gray-100 p-6">
              {editingMatch ? "Edit Match" : "Add Match"}
            </h3>
            <div className="p-6">
              <div className="space-y-4">
                <div className="grid grid-cols-1 xl:grid-cols-3">
                  <div className="xl:col-span-3 py-2 xl:py-4 border-b-[1px] border-gray-100">
                  <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-start md:items-center justify-center">
                    <div className="flex items-center">
                      <label className="">Player 1 : </label>
                    </div>
                    <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2">
                      <label className="block">
                        <input
                          type="text"
                          name="player1"
                          value={matchDetails.player1}
                          onChange={handleMatchChange}
                          className="mt-1 block w-full border-gray-300 rounded-md"
                          placeholder="Name"
                          required
                        />
                      </label>
                      <label className="block">
                        <input
                          type="text"
                          name="player1Mobile"
                          value={matchDetails.player1Mobile}
                          onChange={handleMatchChange}
                          className="mt-1 block w-full border-gray-300 rounded-md"
                          placeholder="Mobile"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <br />
                  <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-start md:items-center justify-center">
                    <div className="flex items-center">
                      <label className="">Player 2 : </label>
                    </div>
                    <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2">
                      <label className="block">
                        <input
                          type="text"
                          name="player2"
                          value={matchDetails.player2}
                          onChange={handleMatchChange}
                          className="mt-1 block w-full border-gray-300 rounded-md"
                          placeholder="Name"
                          required
                        />
                      </label>
                      <label className="block">
                        <input
                          type="text"
                          name="player2Mobile"
                          value={matchDetails.player2Mobile}
                          onChange={handleMatchChange}
                          className="mt-1 block w-full border-gray-300 rounded-md"
                          placeholder="Mobile"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  </div>
                  <br />
                  <div className="xl:col-span-3 grid grid-cols-1 md:grid-cols-3 space-y-2 md:space-y-0 md:space-x-2 py-2 md:py-4 items-center border-b-[1px] border-gray-100">
                    <label className="block">
                      <input
                        type="number"
                        name="sets"
                        value={matchDetails.sets}
                        onChange={handleMatchChange}
                        className="block w-full border-gray-300 rounded-md"
                        placeholder="Sets"
                        min="1"
                        required
                      />
                    </label>
                    <label className="block">
                      <input
                        type="text"
                        name="description"
                        value={matchDetails.description}
                        onChange={handleMatchChange}
                        className="block w-full border-gray-300 rounded-md"
                        placeholder="Match Description"
                        required
                      />
                    </label>

                    <label className="block">
                      <input
                        type="text"
                        name="courtNumber"
                        value={matchDetails.courtNumber}
                        onChange={handleMatchChange}
                        className=" block w-full border-gray-300 rounded-md"
                        placeholder="Court Number"
                        required
                      />
                    </label>
                  </div>
                </div>
                  <div className="flex flex-wrap items-center space-y-2 md:space-y-0 md:justify-between py-2 border-b-[1px] border-gray-100">
                    <div className="">
                    <label className="mr-2">Serving : </label>
                    </div>
                    <div className="">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="serve"
                        value="player1"
                        checked={matchDetails.serve === "player1"}
                        onChange={() =>
                          setMatchDetails((prev) => ({
                            ...prev,
                            serve: "player1",
                          }))
                        }
                        className="form-radio"
                      />
                      <span className="ml-2"> {matchDetails.player1 ? matchDetails.player1 : "Player"}</span>
                    </label>
                    <label className="inline-flex items-center ml-4">
                      <input
                        type="radio"
                        name="serve"
                        value="player2"
                        checked={matchDetails.serve === "player2"}
                        onChange={() =>
                          setMatchDetails((prev) => ({
                            ...prev,
                            serve: "player2",
                          }))
                        }
                        className="form-radio"
                      />
                      <span className="ml-2"> {matchDetails.player2 ? matchDetails.player2 : "Player"}</span>
                    </label>
                    </div>
                  </div>

                <div className="flex flex-wrap items-center space-y-2 md:space-y-0 md:justify-between py-2 border-b-[1px] border-gray-100">
                  <div className="">
                <label className="mr-2">Match Status : </label>
                </div>
                <div className="">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="matchType"
                      value="live"
                      checked={matchDetails.isLive}
                      onChange={() =>
                        setMatchDetails((prev) => ({ ...prev, isLive: true }))
                      }
                      className="form-radio"
                    />
                    <span className="ml-2">Live</span>
                  </label>
                  <label className="inline-flex items-center ml-4">
                    <input
                      type="radio"
                      name="matchType"
                      value="scheduled"
                      checked={!matchDetails.isLive}
                      onChange={() =>
                        setMatchDetails((prev) => ({ ...prev, isLive: false }))
                      }
                      className="form-radio"
                    />
                    <span className="ml-2">Scheduled</span>
                  </label>
                  </div>
                </div>

                {matchDetails.isLive ? (
                  <div>
                    {matchDetails.scores.map((score, index) => (
                      <div key={index} className="mb-4 flex flex-col space-y-2 md:space-y-0 md:flex-row md:items-center md:justify-between">
                        <div>
                        <label className="">Set {index + 1} :</label>
                        </div>
                        <div className="flex space-x-2">
                        <div className="">
                          <label className="block">
                            {matchDetails.player1? matchDetails.player1 : "Player"} Score:
                            <input
                              type="number"
                              value={score.player1Score}
                              onChange={(e) =>
                                handleScoreChange(
                                  index,
                                  "player1",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full border-gray-300 rounded-md"
                            />
                          </label>
                        </div>
                        <div className="">
                          <label className="block">
                             {matchDetails.player2 ? matchDetails.player2 : "Player"} Score:
                            <input
                              type="number"
                              value={score.player2Score}
                              onChange={(e) =>
                                handleScoreChange(
                                  index,
                                  "player2",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full border-gray-300 rounded-md"
                            />
                          </label>
                        </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <label className="block">
                      Scheduled Date and Time:
                      <input
                        type="datetime-local"
                        name="scheduleTime"
                        value={matchDetails.scheduleTime}
                        onChange={handleMatchChange}
                        className="mt-1 block w-full border-gray-300 rounded-md"
                        required
                      />
                    </label>
                  </div>
                )}
              </div>

              <div className="mt-6 flex justify-end gap-4">
                <button
                  onClick={handleSaveMatch}
                  className="bg-blue-500 text-white px-4 py-2 rounded flex items-center gap-2"
                >
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 12a8 8 0 018-8v8h-8z"
                      ></path>
                    </svg>
                  )}
                  {editingMatch ? "Update" : "Save"}
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default MatchDashboard;
