import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { format, isToday, isTomorrow, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import MatchDetailModal from "../Model/MatchDetailModal";

const Matches = () => {
  const [liveMatches, setLiveMatches] = useState([]);
  const [scheduledMatches, setScheduledMatches] = useState([]);
  const [completedMatches, setCompletedMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const fetchPlayerInfo = async (mobileNumber) => {
    console.log("Fetching player info for mobile number:", mobileNumber);
    if (!mobileNumber) {
      return {
        photoURL:
          "https://ik.imagekit.io/gunadev/Badminton/2441904.jpg?updatedAt=1724328594825",
        name: "Unknown Player",
      };
    }
    try {
      console.log("triggered");
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("mobile", "==", mobileNumber));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0].data();
        return {
          photoURL:
            userDoc.photo ||
            "https://ik.imagekit.io/gunadev/Badminton/2441904.jpg?updatedAt=1724328594825",
          name: userDoc.firstname || "Unknown Player",
        };
      } else {
        return {
          photoURL:
            "https://ik.imagekit.io/gunadev/Badminton/2441904.jpg?updatedAt=1724328594825",
          name: "Unknown Player",
        };
      }
    } catch (error) {
      console.error("Error fetching player info:", error);
      return {
        photoURL:
          "https://ik.imagekit.io/gunadev/Badminton/2441904.jpg?updatedAt=1724328594825",
        name: "Unknown Player",
      };
    }
  };

  useEffect(() => {
    const fetchLiveMatches = (selectedDate) => {
      const matchesRef = collection(db, "matches");
      const q = query(
        matchesRef,
        where("isLive", "==", true),
        where("createdDate", "==", selectedDate)
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const matches = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const matchData = doc.data();
            let ongoingSetIndex = -1;
            for (let i = 0; i < matchData.scores.length; i++) {
              const set = matchData.scores[i];
              const player1Score = parseInt(set.player1Score, 10);
              const player2Score = parseInt(set.player2Score, 10);

              if (player1Score < 21 && player2Score < 21) {
                ongoingSetIndex = i;
                break;
              }else if (player1Score === 21 || player2Score === 21){
                ongoingSetIndex = i+1;
                break;
              }
            }


            if (ongoingSetIndex >= matchData.scores.length) {
              ongoingSetIndex = -1; 
            }

      
            const [player1Info, player2Info] = await Promise.all([
              fetchPlayerInfo(matchData.player1Mobile),
              fetchPlayerInfo(matchData.player2Mobile),
            ]);

            return {
              ...matchData,
              ongoingSet:
                ongoingSetIndex !== -1
                  ? matchData.scores[ongoingSetIndex]
                  : null,
              ongoingSetNumber: ongoingSetIndex + 1,
              player1: matchData.player1,
              player1Photo: player1Info.photoURL,
              player2: matchData.player2,
              player2Photo: player2Info.photoURL,
            };
          })
        );

        setLiveMatches(matches.filter((match) => match !== null));
      });

      return unsubscribe; 
    };

    const fetchScheduledMatches = (selectedDate) => {
      const matchesRef = collection(db, "matches");
      const q = query(
        matchesRef,
        where("isLive", "==", false),
        where("isComplete", "==", false),
        where("createdDate", "==", selectedDate)
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const matches = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const matchData = doc.data();

  
            const [player1Info, player2Info] = await Promise.all([
              fetchPlayerInfo(matchData.player1Mobile),
              fetchPlayerInfo(matchData.player2Mobile),
            ]);

            return {
              ...matchData,
              scheduleTime: getFormattedScheduleTime(matchData.scheduleTime),
              player1: matchData.player1,
              player1Photo: player1Info.photoURL,
              player2: matchData.player2,
              player2Photo: player2Info.photoURL,
            };
          })
        );

        setScheduledMatches(matches);
      });

      return unsubscribe; 
    };

    const fetchCompletedMatches = (selectedDate) => {
      const matchesRef = collection(db, "matches");
      const q = query(
        matchesRef,
        where("isComplete", "==", true),
        where("createdDate", "==", selectedDate)
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const matches = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const matchData = doc.data();

            
            const [player1Info, player2Info] = await Promise.all([
              fetchPlayerInfo(matchData.player1Mobile),
              fetchPlayerInfo(matchData.player2Mobile),
            ]);

            return {
              ...matchData,
              player1: matchData.player1,
              player1Photo: player1Info.photoURL,
              player2: matchData.player2,
              player2Photo: player2Info.photoURL,
              finalScore: matchData.scores[matchData.scores.length - 1] || {}, 
            };
          })
        );

        setCompletedMatches(matches);
      });

      return unsubscribe; // Unsubscribe from real-time updates when the component unmounts
    };

    // Call the functions to fetch data and subscribe to real-time updates
    const unsubscribeLive = fetchLiveMatches(selectedDate);
    const unsubscribeScheduled = fetchScheduledMatches(selectedDate);
    const unsubscribeCompleted = fetchCompletedMatches(selectedDate);

    // Cleanup function to unsubscribe from real-time updates
    return () => {
      unsubscribeLive();
      unsubscribeScheduled();
      unsubscribeCompleted();
    };
  }, [selectedDate]);

  const getFormattedScheduleTime = (scheduleTime) => {
    const timeZone = "Asia/Kolkata";

    // Check if the match is today or tomorrow
    const today = new Date();
    const matchDate = new Date(scheduleTime);

    if (format(matchDate, "yyyy-MM-dd") === format(today, "yyyy-MM-dd")) {
      return "Today " + formatInTimeZone(matchDate, timeZone, "h:mm a");
    } else if (
      format(matchDate, "yyyy-MM-dd") ===
      format(today.setDate(today.getDate() + 1), "yyyy-MM-dd")
    ) {
      return "Tomorrow " + formatInTimeZone(matchDate, timeZone, "h:mm a");
    } else {
      return formatInTimeZone(matchDate, timeZone, "dd-MM-yyyy h:mm a");
    }
  };

  const calculateTotalScores = (scores) => {
    let player1Total = 0;
    let player2Total = 0;

    scores.forEach((score) => {
      player1Total += parseInt(score.player1Score || "0", 10);
      player2Total += parseInt(score.player2Score || "0", 10);
    });

    return { player1Total, player2Total };
  };

  const getMatchStatus = (player1Total, player2Total) => {
    if (player1Total > player2Total) {
      return { player1: "W", player2: "L" };
    } else if (player1Total < player2Total) {
      return { player1: "L", player2: "W" };
    } else {
      return { player1: "D", player2: "D" }; // Handle draws if necessary
    }
  };

  const formatScore = (score) => (score === "" ? "--" : score);

  const handleMatchClick = (match) => {
    setSelectedMatch({
      ...match,
      playerInfo: {
        player1: match.player1,
        player2: match.player2,
      },
      ongoingSetNumber: match.ongoingSetNumber || null,
      completed: match.isComplete || false,
    });
  };

  const closeModal = () => {
    setSelectedMatch(null);
  };

  return (
    <section id="matches" className="flex flex-col items-center pb-4">
      <div className="w-screen">
        <div className="date-filter py-4 w-full flex justify-between items-center px-2 md:px-4 gap-2">
          <div className="">
            <label className="text-sm md:text-xl leading-[.8em] font-semibold">Yonex-Sunrise West Zone Interstate Badminton Championship, Anand 2024</label>
          </div>
          <div className="hidden lg:flex w-[300px] items-center justify-center">
          <div className="mx-[2em] grid grid-cols-3 gap-2 md:gap-4 items-center">
            <div className="flex flex-col h-full justify-center items-center">
              <div className=" flex items-center justify-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FScreenshot%202024-08-26%20at%2017.05.03.png?alt=media&token=22533c14-fc6c-4534-b041-a1145de574f2"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col h-full justify-center items-center">
              <div className=" flex items-center justify-center">
                <img
                  src="https://adba.in/images/channels4_profile.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col h-full justify-center items-center">
              <div className="flex items-center justify-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FIMG-20240830-WA0004.jpg?alt=media&token=1641963e-1505-4c49-aa56-6683ad29a25f"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
          <div className="">
          <label className="text-gray-500">Select Date: </label>
          <input
            className="border border-gray-200 bg-transparent rounded-md"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        </div>
        <h2 className="text-xl font-normal mb-4 py-4 px-2 md:px-4 bg-gray-100">
          Live Matches
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-center gap-4 px-4">
          {liveMatches.length > 0 ? (
            liveMatches.map((match, index) => (
              <div
                key={index}
                className="card__wrapper w-full border border-gray-200 rounded-md overflow-hidden"
                onClick={() => handleMatchClick(match)}
              >
                <div className="flex w-full bg-gray-50 p-4 gap-2 items-center">
                  <div className="pr-4 border-r-[1px] border-gray-400">
                    <label className="text-[.9em] text-red-500">
                      <span className="text-red-500 animate-ping">·</span> Live
                    </label>
                  </div>
                  <div className="flex-1">
                    <label>
                      {match.player1}
                      <span className="px-1 text-gray-500">
                        vs
                      </span>
                      {match.player2}
                    </label>
                  </div>
                  <div className="flex items-center space-x-4">
                    <label className="text-[14px] xl:text-[16px]">
                      {match.description}
                    </label>
                    <label className="text-gray-400">
                      <svg
                        className="shrink-0 size-3"
                        height="85.999px"
                        width="46.001px"
                        viewBox="0 0 46.001 85.999"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{
                          enableBackground: "new 0 0 46.001 85.999",
                        }}
                      >
                        <path
                          d="M1.003,80.094c-1.338,1.352-1.338,3.541,0,4.893c1.337,1.35,3.506,1.352,4.845,0l39.149-39.539  
  c1.338-1.352,1.338-3.543,0-4.895L5.848,1.014c-1.339-1.352-3.506-1.352-4.845,0c-1.338,1.352-1.338,3.541-0.001,4.893L36.706,43  
  L1.003,80.094z"
                          fill="currentColor"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
                <div className="px-4 pb-4">
                  <div className="mt-2 mb-4 flex flex-col">
                    <label className="text-[.9em] text-gray-600">
                      {match.ongoingSetNumber > 0
                        ? `Ongoing set: ${match.ongoingSetNumber}`
                        : "In Review"}
                    </label>

                    <label className="text-[.9em] text-gray-600">
                      Court No. : {match.courtNumber}
                    </label>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex w-full justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={match.player1Photo}
                          alt={`${match.player1}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        <label>{match.player1}</label>
                        {match.serve === "player1" && (
                          <img
                            src="https://ik.imagekit.io/gunadev/Badminton/shuttlecock.png?updatedAt=1724655546193" // Replace with your serve icon path
                            alt="serve"
                            className="w-4 h-4 mr-2"
                          />
                        )}
                      </div>

                      <label>
                        <div className="w-full grid grid-cols-3 gap-x-2">
                          {match.scores.map((set, index) => (
                            <div
                              key={index}
                              className="w-full justify-center items-center"
                            >
                              <div className="flex justify-center">
                                <span className="">
                                  {formatScore(set.player1Score)}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </label>
                    </div>
                    <div className="flex w-full justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={match.player2Photo}
                          alt={`${match.player2}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        <label>{match.player2}</label>
                        {match.serve === "player2" && (
                          <img
                            src="https://ik.imagekit.io/gunadev/Badminton/shuttlecock.png?updatedAt=1724655546193" // Replace with your serve icon path
                            alt="serve"
                            className="w-4 h-4 mr-2"
                          />
                        )}
                      </div>
                      <label>
                        <div className="w-full grid grid-cols-3 gap-x-2">
                          {match.scores.map((set, index) => (
                            <div
                              key={index}
                              className="w-full justify-center items-center"
                            >
                              <div className="flex justify-center">
                                <span className="">
                                  {formatScore(set.player2Score)}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 md:col-span-2 xl:col-span-3">
              No live matches available
            </div>
          )}
        </div>
      </div>

      <div className="w-full mt-8">
        <h2 className="text-xl font-normal mb-4 py-4 px-2 md:px-4 bg-gray-100">
          Scheduled Matches
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-center gap-4 px-4">
          {scheduledMatches.length > 0 ? (
            scheduledMatches.map((match, index) => (
              <div
                key={index}
                className="card__wrapper w-full border border-gray-200 rounded-md overflow-hidden"
              >
                <div className="flex w-full bg-gray-50 p-4 gap-2 items-center">
                  <div className="flex-1">
                    <label>
                      {match.player1}
                      <span className="px-1 text-gray-500">
                        vs
                      </span>
                      {match.player2}
                    </label>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      {match.scheduleTime}
                    </label>
                  </div>
                </div>
                <div className="mt-2 px-4 pb-4">
                  <div className="flex flex-col gap-4">
                    <div className="flex w-full justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={match.player1Photo}
                          alt={`${match.player1}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        <label>{match.player1}</label>
                      </div>
                    </div>
                    <div className="flex w-full justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={match.player2Photo}
                          alt={`${match.player2}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        <label>{match.player2}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 md:col-span-2 xl:col-span-3">
              No scheduled matches available
            </div>
          )}
        </div>
      </div>

      <div className="w-full mt-8">
        <h2 className="text-xl font-normal mb-4 py-4 px-2 md:px-4 bg-gray-100">
          Completed Matches
        </h2>

        {completedMatches.length === 0 ? (
          <div className="text-center text-gray-500">No matches completed</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-center gap-4 px-4">
            {completedMatches.map((match) => {
              const { player1Total, player2Total } = calculateTotalScores(
                match.scores
              );
              const status = getMatchStatus(player1Total, player2Total);

              return (
                <div
                  key={match.id}
                  className="w-full bg-white rounded shadow"
                  onClick={() => handleMatchClick(match)}
                >
                  <h3 className="text-lg font-normal p-4 bg-gray-50">
                    {match.player1}
                    <span className="px-1 text-gray-500">
                        vs
                      </span>
                    {match.player2}
                  </h3>
                  <table className="mt-2 w-full border-collapse hidden">
                    <thead>
                      <tr>
                        <th className="border p-2">Set</th>
                        <th className="border p-2">{match.player1}</th>
                        <th className="border p-2">{match.player2}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {match.scores.map((score, index) => (
                        <tr key={index}>
                          <td className="border p-2 text-center">
                            Set {index + 1}
                          </td>
                          <td className="border p-2 text-center">
                            {formatScore(score.player1Score)}
                          </td>
                          <td className="border p-2 text-center">
                            {formatScore(score.player2Score)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="mt-2 p-4 space-y-3">
                    <div
                      className={`flex justify-between ${
                        status.player1 === "L"
                          ? "text-gray-400"
                          : "text-green-600"
                      }`}
                    >
                      <span className="flex gap-1">
                        <img
                          src={match.player1Photo}
                          alt={`${match.player1}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        {match.player1}
                        <span> - {status.player1}</span>
                      </span>
                      <label>
                        <div className="w-full grid grid-cols-3 gap-x-2">
                          {match.scores.map((set, index) => (
                            <div
                              key={index}
                              className="w-full justify-center items-center"
                            >
                              <div className="flex justify-center">
                                <span className="">
                                  {formatScore(set.player1Score)}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </label>
                    </div>
                    <div
                      className={`flex justify-between ${
                        status.player2 === "L"
                          ? "text-gray-400"
                          : "text-green-600"
                      }`}
                    >
                      <span className="flex gap-1">
                        <img
                          src={match.player2Photo}
                          alt={`${match.player2}'s photo`}
                          className="w-6 h-6 rounded-full border border-gray-100"
                        />
                        {match.player2}
                        <span> - {status.player2}</span>
                      </span>
                      
                      <label>
                        <div className="w-full grid grid-cols-3 gap-x-2">
                          {match.scores.map((set, index) => (
                            <div
                              key={index}
                              className="w-full justify-center items-center"
                            >
                              <div className="flex justify-center">
                                <span className="">
                                  {formatScore(set.player2Score)}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {selectedMatch && (
        <MatchDetailModal matchData={selectedMatch} onClose={closeModal} />
      )}
    </section>
  );
};

export default Matches;
