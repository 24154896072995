import { useState, useEffect } from 'react';
import { doc, setDoc, getDocs, collection, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { format } from 'date-fns';

const JuiceQrScanner = () => {
  const [error, setError] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAvailable(true);
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        setCameraAvailable(false);
        setError('Camera is not available.');
        toast.error('Camera is not available.');
      }
    };
    checkCameraAvailability();
  }, []);

  const handleScan = (data) => {
    if (data && data.length > 0) {
      try {
        const rawValue = data[0]?.rawValue;
        if (rawValue) {
          const scannedData = JSON.parse(rawValue);
          handleVerification(scannedData); 
        } else {
          throw new Error('No raw value found in the scan data.');
        }
      } catch (error) {
        console.error('Error parsing QR code data:', error);
        setError('Error parsing QR code data.');
        toast.error('Error parsing QR code data.');
        setIsScanning(false); 
      }
    } else {
      console.error('No data found from the QR scan.');
      setError('No data found from the QR scan.');
      toast.error('No data found from the QR scan.');
      setIsScanning(false); 
    }
  };

  const handleError = (err) => {
    console.error('Scanner error:', err);
    setError('Error scanning QR code.');
    toast.error('Error scanning QR code.');
    setIsScanning(false);
  };

  const handleScanNowClick = () => {
    if (!cameraAvailable) {
      setError('Camera is not available.');
      toast.error('Camera is not available.');
      return;
    }
    setIsScanning(true);
  };

  const handleVerification = async (data) => {
    if (!data) {
      console.error('No scan data available');
      setError('No scan data available.');
      toast.error('No scan data available.');
      setIsScanning(false);
      return;
    }

    

    setIsVerifying(true);
    try {
      const now = new Date();
      const todayDate = now.toISOString().split('T')[0];
      const hours = now.getHours();
      const minutes = now.getMinutes().toString().padStart(2, '0'); 
      const seconds = now.getSeconds().toString().padStart(2, '0'); 
      const hourString = hours.toString().padStart(2, '0');

      let collectionName = '';

      if (hours >= 11 && hours < 15) {
        collectionName = 'morningJuice';
      } else if (hours >= 15 && hours < 19) {
        collectionName = 'afternoonJuice';
      } else if (hours >= 19 && hours < 23) {
        collectionName = 'eveningJuice';
      } else {
        collectionName = 'juiceUnlabelledScans';
      }

      // Fetch user information from the 'users' collection
      const usersRef = collection(db, 'users');
      const userQuery = query(usersRef, where('mobile', '==', data.mobile));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        console.error('No matching user found');
        setError('No matching user found.');
        toast.error('No matching user found.');
        return;
      }

      const userData = userSnapshot.docs[0].data();
      const userRole = userData.role;
      setUserDetails(userData);  // Store user data to be displayed in the modal


      // Restrict access for users with roles other than 'player' or 'tournament organizer'
      if (userRole !== 'player' && userRole !== 'tournament organiser') {
        console.error('You are not authorized.');
        setError('You are not authorized.');
        toast.error('You are not authorized.');
        return;
      }

      let newDocId;
    
        newDocId = `${data.mobile}_${todayDate}_${hourString}_${minutes}_${seconds}`;
      

      const generalDocRef = doc(db, 'juiceScannedData', newDocId);
      const timeSpecificDocRef = doc(db, collectionName, newDocId);

      await setDoc(generalDocRef, {
        mobile : data.mobile,
        date: todayDate,
        lastScannedTime: Timestamp.fromDate(now)
      }, { merge: true });

      await setDoc(timeSpecificDocRef, {
        mobile : data.mobile,
        date: todayDate,
        lastScannedTime: Timestamp.fromDate(now)
      }, { merge: true });

      console.log('User verified successfully!');
      setIsModalOpen(true); // Show the modal with the success
    } catch (error) {
      console.error('Error storing data:', error);
      setError(`Error storing data: ${error.message}`);
      toast.error(`Error storing data: ${error.message}`);
    } finally {
      setIsScanning(false);
      setIsVerifying(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserDetails(null); // Clear user details when closing modal
  };
  

  return {
    error,
    isScanning,
    cameraAvailable,
    isVerifying,
    isModalOpen,
    userDetails,
    closeModal,
    handleScan,
    handleError,
    handleScanNowClick,
    handleVerification
  };
};

export default JuiceQrScanner;
