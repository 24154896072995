import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { users as initialUsers } from '../data/users';

export const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const foundUser = initialUsers.find(user => user.id === parseInt(id));
    if (foundUser) setUser(foundUser);
  }, [id]);

  const handleSave = () => {
    
    const updatedUsers = initialUsers.map(u => u.id === user.id ? user : u);
    
    initialUsers = updatedUsers; 
    navigate('/all-users');
  };

  return user ? (
    <div>
      <h1>Edit User</h1>
      <form onSubmit={handleSave}>
        <input type="text" value={user.firstname} onChange={(e) => setUser({ ...user, firstname: e.target.value })} />
        <input type="text" value={user.lastname} onChange={(e) => setUser({ ...user, lastname: e.target.value })} />
        <input type="mobile" value={user.mobile} onChange={(e) => setUser({ ...user, mobile: e.target.value })} />
        <input type="email" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
        <input type="date" value={user.date} onChange={(e) => setUser({ ...user, date: e.target.value })} />
        <input type="text" value={user.gender} onChange={(e) => setUser({ ...user, gender: e.target.value })} />
        
        <button type="submit">Save</button>
      </form>
    </div>
  ) : <p>Loading...</p>;
};

export default EditUser;
