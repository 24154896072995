

import React, { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { useTheme } from '@emotion/react';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust path to your Firebase configuration
import { format, subDays, startOfDay, endOfDay } from 'date-fns';

const AreaChart = () => {
  const theme = useTheme();
  const [data, setData] = useState({ categories: [], series: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading state to true before fetching data
        console.log('Fetching data from Firestore...');

        // Get the current date and calculate the start date for the 6-day range
        const endDate = new Date();
        const startDate = subDays(endDate, 6);
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        console.log(`Querying documents from ${formattedStartDate} to ${formattedEndDate}`);

        // Initialize objects to store scan counts
        const scanCounts = {};
        const categories = [];

        // Query documents for each day within the 6-day range
        for (let i = 0; i <= 6; i++) {
          const date = subDays(endDate, i);
          const formattedDate = format(date, 'yyyy-MM-dd'); // Format date as yyyy-MM-dd
          categories.push(formattedDate);

          // Define start and end times for the current day
          const startOfDayDate = startOfDay(date);
          const endOfDayDate = endOfDay(date);
          const startOfDayISO = Timestamp.fromDate(startOfDayDate);
          const endOfDayISO = Timestamp.fromDate(endOfDayDate);

          // Query Firestore for documents of the current date
          const q = query(
            collection(db, 'scanned'),
            where('lastScannedTime', '>=', startOfDayISO),
            where('lastScannedTime', '<=', endOfDayISO)
          );

          const querySnapshot = await getDocs(q);
          console.log(`Found ${querySnapshot.size} documents for ${formattedDate}`);

          scanCounts[formattedDate] = querySnapshot.size;
        }

        console.log('Scan counts:', scanCounts);

        // Prepare data for the chart
        const seriesData = categories.map(date => scanCounts[date] || 0);

        // Reverse the order to have the most recent dates on the right
        const reversedCategories = categories.reverse();
        const reversedSeriesData = seriesData.reverse();

        console.log('Categories:', reversedCategories);
        console.log('Series Data:', reversedSeriesData);

        setData({
          categories: reversedCategories,
          series: [{ name: 'Total Scans', data: reversedSeriesData }],
        });

        setLoading(false); // Set loading state to false after data is fetched
        console.log('Data successfully fetched and state updated');

      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        setLoading(false); // Ensure loading state is turned off even if there is an error
      }
    };

    fetchData();
  }, [theme]);

  const chartOptions = {
    chart: {
      height: 300,
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: data.series,
    xaxis: {
      categories: data.categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: '#9ca3af',
          fontSize: '13px',
          fontFamily: 'Inter, ui-sans-serif',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#9ca3af',
          fontSize: '13px',
          fontFamily: 'Inter, ui-sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => (value >= 1000 ? `${value / 1000}k` : value),
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: (value) =>
          `${value >= 1000 ? `${value / 1000}k` : value}`,
      },
    },
    responsive: [
      {
        breakpoint: 568,
        options: {
          chart: {
            height: 300,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#9ca3af',
                fontSize: '11px',
                fontFamily: 'Inter, ui-sans-serif',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#9ca3af',
                fontSize: '11px',
                fontFamily: 'Inter, ui-sans-serif',
                fontWeight: 400,
              },
              formatter: (value) =>
                value >= 1000 ? `${value / 1000}k` : value,
            },
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (!loading) {
      console.log('Rendering chart with options:', chartOptions);
      const chart = new ApexCharts(document.querySelector('#area-chart'), chartOptions);
      chart.render();
      
      return () => {
        console.log('Destroying chart');
        chart.destroy();
      };
    }
  }, [data, theme, loading]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div id="area-chart"></div>
      )}
    </div>
  );
};

export default AreaChart;

