import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const PasswordChangeModal = ({ isOpen, onClose, onSubmit }) => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    setStatusMessage(""); // Clear any previous messages

    try {
      await onSubmit(password);
      setStatusMessage("Password updated successfully!");
      toast.success("Password updated successfully!");
    } catch (error) {
      setStatusMessage("Error updating password. Please try again.");
      toast.error("Error updating password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-black/60 flex items-center justify-center z-50 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-lg font-semibold mb-4">Update Password</h2>
        <input
          type="password"
          className="border border-gray-300 rounded-md w-full p-2 mb-4"
          placeholder="Enter new password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
        />
        <div className="flex justify-end">
          <button
            className="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 ${
              isLoading ? "bg-blue-300" : "bg-blue-600"
            } text-white rounded-md`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
