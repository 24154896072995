import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, requiredRole }) => {
  const userData = localStorage.getItem('userData'); 
  const parsedUserData = userData ? JSON.parse(userData) : null; 
  const isAuthenticated = !!parsedUserData; 
  const userRole = parsedUserData ? parsedUserData.role : null; 

  console.log('PrivateRoute check:', { 
    isAuthenticated, 
    userRole, 
    requiredRole 
  });

  if (!isAuthenticated) {
    console.log('User is not authenticated. Redirecting to login.');
    return <Navigate to="/" replace />;
  }

  const hasAccess = Array.isArray(requiredRole)
    ? requiredRole.includes(userRole)
    : userRole === requiredRole;

  if (!hasAccess) {
    console.log(`User role (${userRole}) does not match required role(s) (${Array.isArray(requiredRole) ? requiredRole.join(', ') : requiredRole}). Redirecting to login.`);
    return <Navigate to="/" replace />;
  }

  console.log('Access granted to:', { 
    component: Component.name, 
    role: userRole 
  });

  return <Component />;
};

export default PrivateRoute;
