import React from 'react';
const Preloader = () => {
    return (
        <div className='w-screen h-[100svh] overflow-hidden flex justify-center items-center'>
        <div className='pulse'></div>
        </div>
    );
};

export default Preloader;
