import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import bcrypt from 'bcryptjs';

const EditUserModal = ({ userDetails, onClose, onUpdate }) => {
  const [editedUser, setEditedUser] = useState({ ...userDetails, password: "" }); // Initialize with empty password
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);
  
    try {
      const updatedUserData = { ...editedUser };
  
      // Check if a new password has been entered
      if (editedUser.password && editedUser.password.trim().length > 0) {
        console.log("Password provided, hashing...");
        const salt = await bcrypt.genSalt(10);
        const hashedPassword = await bcrypt.hash(editedUser.password, salt);
        updatedUserData.password = hashedPassword; // Store hashed password
      } else {
        console.log("No password provided, skipping password update.");
        delete updatedUserData.password; // Remove the password field if it's empty
      }
  
      const userRef = doc(db, "users", userDetails.userId);
      await updateDoc(userRef, updatedUserData);
  
      console.log("User updated:", updatedUserData);
      onUpdate(updatedUserData);
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 2000); // Close modal after 2 seconds
    } catch (error) {
      console.error("Error updating user:", error);
      setError("Failed to update user. Please try again.");
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="fixed inset-0 z-[999] flex items-end sm:items-center justify-center bg-gray-500 bg-opacity-50"
      role="dialog"
      aria-labelledby="edit-user-title"
      aria-modal="true"
    >
      <div className="bg-white p-4 rounded-md shadow-lg text-center w-full max-w-md">
        <div className="flex justify-end">
          <button
            aria-label="Close"
            className="text-gray-600 hover:text-gray-800"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>
        <h2 id="edit-user-title" className="text-xl font-medium mb-4">
          Edit User
        </h2>
        <div className="text-left">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstname"
              value={editedUser.firstname}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastname"
              value={editedUser.lastname}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={editedUser.email}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Mobile
            </label>
            <input
              type="text"
              name="mobile"
              value={editedUser.mobile}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              name="status"
              value={editedUser.status}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <select
              name="role"
              value={editedUser.role}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="vendor">Vendor</option>
              <option value="juice vendor">Juice Vendor</option>
              <option value="player">Player</option>
              <option value="tournament organiser">Tournament Organiser</option>
              <option value="tournament official">Tournament Official</option>
              <option value="team manager">Team Manager</option>
              <option value="state secretary">State Secretary</option>
              <option value="coach">Coach</option>
              <option value="tournament doctor">Tournment Doctor</option>
              <option value="physio">Physio</option>
              <option value="support staff">Support Staff</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div className="relative mb-4">
            <label className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={editedUser.password || ""}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter new password"
            />
            <span
              className="absolute top-[2.5em] right-0 pr-3 flex items-center cursor-pointer text-gray-400 text-sm"
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          {loading && (
            <div className="bg-yellow-500 text-white p-2 mb-4 rounded">
              Updating user, please wait...
            </div>
          )}
          {error && (
            <div className="bg-red-500 text-white p-2 mb-4 rounded">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-500 text-white p-2 mb-4 rounded">
              User updated successfully!
            </div>
          )}
          <button
            onClick={handleUpdate}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            disabled={loading} // Disable the button while loading
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
