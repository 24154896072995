// src/components/BarChart.js
import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { format, subDays } from 'date-fns';
import { fetchDailyCounts } from '../api/fetchDailyCounts';

const BarChart = () => {
  const [chartData, setChartData] = useState({ morning: [], afternoon: [], evening: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const endDate = format(new Date(), 'yyyy-MM-dd'); // Current date
        const startDate = format(subDays(new Date(), 6), 'yyyy-MM-dd'); // 6 days ago

        const data = await fetchDailyCounts(startDate, endDate);

        setChartData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      height: 300,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    series: [
      {
        name: 'Lunch',
        data: chartData.morning,
      },
      {
        name: 'High Tea',
        data: chartData.afternoon,
      },
      {
        name: 'Dinner',
        data: chartData.evening,
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10px',
        borderRadius: 0,
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 8,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        format(subDays(new Date(), 6), 'dd MMM'),
        format(subDays(new Date(), 5), 'dd MMM'),
        format(subDays(new Date(), 4), 'dd MMM'),
        format(subDays(new Date(), 3), 'dd MMM'),
        format(subDays(new Date(), 2), 'dd MMM'),
        format(subDays(new Date(), 1), 'dd MMM'),
        format(new Date(), 'dd MMM'),
      ],
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { show: false },
      labels: {
        style: {
          colors: '#9ca3af',
          fontSize: '13px',
          fontFamily: 'Inter, ui-sans-serif',
          fontWeight: 400,
        },
        offsetX: -2,
        formatter: (title) => title.slice(0, 6),
      },
    },
    yaxis: {
      labels: {
        align: 'left',
        minWidth: 0,
        maxWidth: 140,
        style: {
          colors: '#9ca3af',
          fontSize: '13px',
          fontFamily: 'Inter, ui-sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => (value >= 1000 ? `${value / 1000}k` : value),
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `${value >= 1000 ? `${value / 1000}k` : value}`,
      },
    },
    responsive: [
      {
        breakpoint: 568,
        options: {
          chart: { height: 300 },
          plotOptions: { bar: { columnWidth: '14px' } },
          stroke: { width: 8 },
          labels: {
            style: {
              colors: '#9ca3af',
              fontSize: '11px',
              fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 400,
            },
            offsetX: -2,
            formatter: (title) => title.slice(0, 3),
          },
          yaxis: {
            labels: {
              align: 'left',
              minWidth: 0,
              maxWidth: 140,
              style: {
                colors: '#9ca3af',
                fontSize: '11px',
                fontFamily: 'Inter, ui-sans-serif',
                fontWeight: 400,
              },
              formatter: (value) => (value >= 1000 ? `${value / 1000}k` : value),
            },
          },
        },
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="hs-multiple-bar-charts">
      <ApexCharts options={options} series={options.series} type="bar" height={300} />
    </div>
  );
};

export default BarChart;
