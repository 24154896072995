// src/api/fetchDailyCounts.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this is correctly importing your Firebase configuration
import { format, subDays } from 'date-fns';

const fetchDailyCounts = async (startDate, endDate) => {
  try {
    const collections = ['morning', 'afternoon', 'evening'];
    const countData = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    // Helper function to fetch count
    const fetchCount = async (collectionName) => {
      const counts = [];
      for (let i = 0; i <= 6; i++) {
        const date = format(subDays(new Date(endDate), i), 'yyyy-MM-dd');
        const collectionRef = collection(db, collectionName);
        const q = query(collectionRef, where('date', '==', date));
        const querySnapshot = await getDocs(q);
        counts.push(querySnapshot.size);
      }
      return counts.reverse(); // Return the counts in chronological order
    };

    // Fetch counts for all collections
    const morningCounts = await fetchCount('morning');
    const afternoonCounts = await fetchCount('afternoon');
    const eveningCounts = await fetchCount('evening');

    countData.morning = morningCounts;
    countData.afternoon = afternoonCounts;
    countData.evening = eveningCounts;

    return countData;
  } catch (error) {
    console.error('Error fetching daily counts:', error);
    throw error;
  }
};

export { fetchDailyCounts };
