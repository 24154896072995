import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage, ref, uploadBytesResumable, getDownloadURL, setDoc, doc, getDoc } from '../firebase';
import Modal from '../Model/Missmatch.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const MainRegister = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: '',
    userId:'',
    mobile: '',
    email: '',
    date: '',
    gender: '',
    bloodgroup: '',
    photo: null,
    aadhar: null,
    state: '',
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [qrData, setQrData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pageAccessible, setPageAccessible] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const registered = localStorage.getItem('registered');
    if (registered === 'true') {
      setPageAccessible(false);
    }
  }, []);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setFormData((prevData) => ({ ...prevData, [id]: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    }
  };

  const uploadImage = async (file, folderPath) => {
    if (!file) return '';
    const storageRef = ref(storage, `users/${folderPath}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstname, lastname, password, confirmPassword,userId, mobile, email, date, gender, bloodgroup, photo, aadhar, state } = formData;

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setShowModal(true);
      return;
    }

    try {
      const userDoc = await getDoc(doc(db, 'users', mobile));
      if (userDoc.exists()) {
        alert('User already exists. Redirecting to login.');
        navigate('/', { state: { mobile } });
        return;
      }

      const folderPath = `${firstname}_${lastname}`;
      const photoURL = photo ? await uploadImage(photo, folderPath) : '';
      const aadharURL = aadhar ? await uploadImage(aadhar, folderPath) : '';

      const userData = {
        firstname,
        lastname,
        userId,
        mobile,
        email,
        date,
        gender,
        bloodgroup,
        photo: photoURL,
        aadhar: aadharURL,
        state
      };

      const qrDataString = JSON.stringify(userData);
      setQrData(qrDataString);

      await setDoc(doc(db, 'users', mobile), {
        ...userData,
        password,
        confirmPassword,
        qrData: qrDataString,
        isAdmin: false,
        createdAt: new Date(),
      });

      localStorage.setItem('registered', 'true');
      resetForm();
      setShowSuccessMessage(true);

      setTimeout(() => {
        navigate('/');
      }, 3000);

    } catch (error) {
      console.error('Registration error', error);
      alert('Error registering. Please check your details and try again.');
    }
  };

  const resetForm = () => {
    setFormData({
      firstname: '',
      lastname: '',
      password: '',
      confirmPassword: '',
      userId: '',
      mobile: '',
      email: '',
      date: '',
      gender: '',
      bloodgroup: '',
      photo: null,
      aadhar: null,
      state: '',
    });
    setError('');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (!pageAccessible) {
    return (
      <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center mx-auto w-screen">
        <div className="text-center p-5 bg-white shadow sm:rounded-lg">
          <h2 className="text-2xl font-semibold text-red-600">You are already registered!</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center mx-auto w-screen">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="flex-1 bg-indigo-100 text-center hidden lg:flex w-1/2 ">
          <div
            className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat "
            style={{
              backgroundImage: "url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg')",
            }}
          ></div>
        </div>
        <div className="w-full lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/qr-application-50460.appspot.com/o/Logos%2FWhatsApp%20Image%202024-07-21%20at%2016.04.29_abaf3be5.jpg?alt=media&token=582e345b-4fc4-4dd2-a934-c8c3cb37f2fb"
              className="w-40 mx-auto" alt="logo" />
          </div>
          <form className="mt-6 flex flex-col items-center" onSubmit={handleSubmit} id="registerForm">
            <div className="w-full flex-1 mt-4 overflow-y-auto max-h-96">
              <div className="mx-auto max-w-xs">
                <div className="flex space-x-4">
                  <input
                    className="w-1/2 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    id="firstname"
                    placeholder="First Name."
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="w-1/2 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    id="lastname"
                    placeholder="Last Name"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* <input
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  type="text"
                  minLength={10}
                  maxLength={10}
                  id="userId"
                  placeholder="UserId (Enter your mobile no.)"
                  value={formData.userId}
                  onChange={handleChange}
                  required
                /> */}
                <input
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  type="number"
                  minLength={10}
                  maxLength={10}
                  id="mobile"
                  placeholder="Mobile No."
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                <input
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  type="email"
                  id="email"
                  placeholder="Email."
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                 <input
                    type="date"
                    id="date"
                    onChange={handleChange}
                    required
                    className="mt-5 w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                
                <select
                  id="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <select
                  id="bloodgroup"
                  value={formData.bloodgroup}
                  onChange={handleChange}
                  required
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
                <div className="mt-5">
                  <label htmlFor="photo">Upload Photo:</label>
                  <input
                    type="file"
                    id="photo"
                    accept="image/*"
                    onChange={handleChange}
                    required
                    className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                </div>
                <div className="mt-5">
                  <label htmlFor="aadhar">Upload Aadhar:</label>
                  <input
                    type="file"
                    id="aadhar"
                    accept="image/*"
                    onChange={handleChange}
                    required
                    className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                </div>
                <select
                  id="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  className="w-full px-8 py-4 mt-5 rounded-lg font-medium bg-gray-100 border border-gray-200 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="">Select State</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
                <div className="relative mt-5">
                  <input
                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="Password."
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-3 text-gray-600 focus:outline-none"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
                <div className="relative mt-5">
                  <input
                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    placeholder="Confirm Password."
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-3 top-3 text-gray-600 focus:outline-none"
                  >
                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 px-10 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
              >
                {loading ? 'Loading...' : 'Registration'}
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          errorMessage={error}
        />
      )}
      {showSuccessMessage && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg text-center">
            <div className="mb-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-4xl" />
            </div>
            <h2 className="text-2xl font-semibold text-green-600">Successfully Registered!</h2>
            <p className="mt-4">Thank you for registering.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainRegister;
